/* ======================================================================== */
/* 73. postMeta */
/* ======================================================================== */
.post-meta
	+reset-ul
	font-size: 13px
	line-height: 1
	font-weight: 500
	i
		font-size: 14px !important
		vertical-align: middle
		margin-right: 4px
		// margin-bottom: 2px
	li
		display: inline-flex
		flex-wrap: wrap
		align-items: center
		line-height: 1.6
		vertical-align: middle
		span
			vertical-align: middle
	li:not(:last-child):after
		content: '/'
		color: $color-dark-1
		display: inline-block
		margin-left: 6px
		margin-right: 5px
		+trans1
		// transition: opacity 0.6s ease
	ul
		padding-left: 0
	a
		display: inline-block
		vertical-align: middle
		white-space: nowrap
.post-meta_block
	li
		display: block
		margin: 0.5em 0
	li:not(:last-child):after
		display: none
.post-meta_flex
	display: flex
	justify-content: space-between
	li:not(:last-child):after
		display: none
.post-meta__divider
	display: inline-block
	vertical-align: middle
	margin: 0 1em
	@include fluid('width', 60, 100)
	height: 1px
	background-color: $color-border-dark
.post-meta_mini
	li:after
		display: none !important
.post-meta_mini-small
	li:after
		display: none !important
	.post-meta__divider
		width: 50px
