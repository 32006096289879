/* ======================================================================== */
/* 38. overlay */
/* ======================================================================== */
.overlay
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: -1
	backface-visibility: hidden
	pointer-events: none
.overlay_dark // fallback
	background-color: rgba(0, 0, 0, .6)
.overlay_light // fallback
	background-color: rgba(255, 255, 255, .9)
@for $i from 1 through 9
	.overlay_dark-#{$i * 10}
		background-color: rgba(0, 0, 0, $i / 10)
@for $i from 1 through 9
	.overlay_light-#{$i * 10}
		background-color: rgba(255, 255, 255, $i / 10)
