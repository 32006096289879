/* ======================================================================== */
/* 107. sectionMasthead */
/* ======================================================================== */
.section-masthead
.section-masthead__inner
	position: relative
	overflow: hidden
	z-index: 50
.section-masthead__background
	overflow: hidden
.section-masthead__background_fullscreen, .section-masthead__background_halfscreen
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	overflow: hidden
.section-masthead__info
	position: relative
	z-index: 50
.section-masthead__wrapper-content
	position: relative
	z-index: 50
.section-masthead__wrapper-scroll-down
	display: inline-block
	position: absolute
	bottom: var(--gutter-horizontal)
	z-index: 50
.text-center .section-masthead__wrapper-scroll-down
	left: 0
	right: 0
	margin: auto
.text-start .container-fluid .section-masthead__wrapper-scroll-down
	left: var(--gutter-horizontal)
.text-end .container-fluid .section-masthead__wrapper-scroll-down
	right: var(--gutter-horizontal)
.text-start .container .section-masthead__wrapper-scroll-down
	left: 0
.text-end .container .section-masthead__wrapper-scroll-down
	right: 0
