/* * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 *
 * Kinsey – AJAX Agency HTML5 Template
 *
 * [Table of Contents]
 *
 * 1. author
 * 2. comments
 * 3. figurePost
 * 4. gallery
 * 5. pagination
 * 6. post
 * 7. postMeta
 * 8. sidebar
 * 9. tags
 * 10. bootstrap
 * 11. button
 * 12. buttonBordered
 * 13. buttonSolid
 * 14. buttonCircle
 * 15. buttonCircles
 * 16. card
 * 17. cardPortfolio
 * 18. categories
 * 19. colors
 * 20. counter
 * 21. cursor
 * 22. backgroundHover
 * 23. hoverZoom
 * 24. hoverZoomUnderline
 * 25. maskReveal
 * 26. underline
 * 27. feature
 * 28. figureCategory
 * 29. figureImage
 * 30. figureMember
 * 31. figureProject
 * 32. filter
 * 33. fluidMargins
 * 34. fluidMarginsNegative
 * 35. fluidPaddings
 * 36. fluidPaddingsOffsets
 * 37. fluidTop
 * 38. form
 * 39. formContacts
 * 40. inputFloat
 * 41. inputSearch
 * 42. select
 * 43. gmap
 * 44. grid
 * 45. gridFluid
 * 46. lazyLoad
 * 47. footer
 * 48. footerLogo
 * 49. header
 * 50. headerLogo
 * 51. headerSticky
 * 52. headerThemes
 * 53. preloader
 * 54. spinner
 * 55. listBackgrounds
 * 56. listBackgroundsThemes
 * 57. listDots
 * 58. logo
 * 59. modal
 * 60. breadcrumbs
 * 61. menu
 * 62. menuOverlay
 * 63. overlay
 * 64. pswp
 * 65. asideCounters
 * 66. section
 * 67. section404
 * 68. sectionAwards
 * 69. sectionBlog
 * 70. sectionComingSoon
 * 71. sectionContent
 * 72. sectionForm
 * 73. sectionFullheight
 * 74. sectionFullscreenColumns
 * 75. sectionHeight
 * 76. sectionImage
 * 77. sectionIntro
 * 78. sectionList
 * 79. sectionListBackgrounds
 * 80. sectionLogos
 * 81. sectionMap
 * 82. sectionMasthead
 * 83. sectionNavProjects
 * 84. sectionOffset
 * 85. sectionScrollThemeSwitch
 * 86. sectionServices
 * 87. sectionSliderImages
 * 88. sectionSliderProjects
 * 89. sectionSliderProjectsFullscreen
 * 90. sectionTestimonials
 * 91. sectionVideo
 * 92. sectionHorizontalScroll
 * 93. slider
 * 94. sliderDots
 * 95. sliderImages
 * 96. sliderMenu
 * 97. sliderProjects
 * 98. sliderProjectsFullscreen
 * 99. sliderProjectsHalfscreen
 * 100. sliderTestimonials
 * 101. smoothScroll
 * 102. social
 * 103. swatch
 * 104. table
 * 105. themes
 * 106. transitionCurtain
 * 107. typography
 * 108. utilities
 * 109. widget
 * 110. widgetArchive
 * 111. widgetCalendar
 * 112. widgetCategories
 * 113. widgetMenuInline
 * 114. widgetNavMenu
 * 115. widgetPolylang
 * 116. widgetRecentComments
 * 117. widgetRecentEntries
 * 118. widgetRSS
 * 119. widgetSocial
 * 120. widgetSocial
 * 121. widgetTagCloud
 * 122. widgetText
 * 123. widgetWPML

 * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 */

@mixin fluid($property, $min-value, $max-value, $min-vw: $min_width, $max-vw: $max_width, $multiplier: 1) {
  #{$property}: calc(#{$multiplier} * (#{$min-value} * 1px));
  @media screen and (min-width: $min-vw) {
    #{$property}: calc(#{$multiplier} * (#{$min-value} * 1px + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))); }
  @media screen and (min-width: $max-vw) {
    #{$property}: calc(#{$multiplier} * (#{$max-value} * 1px)); } }

@mixin fluid-translate-y($min-value, $max-value, $min-vw: $min_width, $max-vw: $max_width, $multiplier: 1) {
  transform: translateY(calc(#{$multiplier} * #{$min-value} * 1px ));
  @media only screen and (min-width: $min-vw) and (max-width: $max-vw) {
    transform: translateY(calc(#{$multiplier} * ((#{$min-value} * 1px) + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})))); }
  @media only screen and (min-width: $max-vw) {
    transform: translateY(calc(#{$multiplier} * #{$max-value} * 1px )); } }

@mixin fluid-type($min-font-size, $max-font-size, $min-vw: $min_width, $max-vw: $max_width) {
  font-size: calc(#{$min-font-size} * 1px);
  @media screen and (min-width: $min-vw) {
    font-size: calc(#{$min-font-size} * 1px + (#{$max-font-size} - #{$min-font-size}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})); }
  @media screen and (min-width: $max-vw) {
    font-size: calc(#{$max-font-size} * 1px); } }

@function strip-unit($value) {
	@return $value / ($value * 0 + 1); }

@function em($pixels, $context: $em-base) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px; }

	@if (unitless($context)) {
		$context: $context * 1px; }

	@return $pixels / $context * 1em; }

@function rem($pixels, $context: $em-base) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px; }

	@if (unitless($context)) {
		$context: $context * 1px; }

	@return $pixels / $context * 1rem; }

$em-base: 16px;
$line-height: 1.8;

// Transitions
@mixin trans1($property: all) {
	transition: $property 0.2s ease-in-out; }
@mixin trans2($property: all) {
	transition: $property 0.6s cubic-bezier(0.785, 0.135, 0.150, 0.860); }
@mixin trans3($property: transform) {
	transition: $property 0.6s ease; }
@mixin reset-ul {
	list-style-type: none;
	padding: 0;
	margin: 0; }

// Dropcap
@mixin dropcap {
	float: left;
	@include fluid-type(var(--dropcap-min-font-size), var(--dropcap-max-font-size));
	color: var(--dropcap-color-dark);
	line-height: 0.7;
	font-style: normal;
	margin: 0.15em 0.25em 0 0;
	font-weight: 100;
	&:after {
		content: '';
		display: table;
		clear: both; } }

@mixin hoverZoomEffect {
	// &.figure-project
	// 	.hover-zoom__zoom
	// 		transform: scale(1.05)
	.hover-zoom__zoom {
		transform: scale(1); }
	.hover-zoom__inner {
		transform: scale(1.05); }
	// .section-image__caption-horizontal, .hover-zoom__caption
	// 	transform: translate(0, -15px)
	// 	transition-delay: 0.06s
	// 	&.text-left
	// 		transform: translate(15px, -15px)
	// 	&.text-end
	// 		transform: translate(-15px, -15px)
	// .section-image__caption-horizontal_top
	// 	transform: translate(0, 15px)
	// 	transition-delay: 0.06s
	// 	&.text-left
	// 		transform: translate(15px, 15px)
	// 	&.text-end
 }	// 		transform: translate(-15px, 15px)

@mixin hoverUnderlineEffect {
	.underline-hover__target {
		background-position: 0% 100% !important;
		background-size: 100% 2px !important;
		.arts-split-text__line {
			background-position: 0% 100% !important;
			background-size: 100% 2px !important; } } }

// Responsive Breakpoints
$xxl: 1400px;
$xl: 1280px;
$lg: 1024px;
$md: 991px;
$sm: 767px;
$xs: 576px;

// Default min/max screen width (for fluid mixins)
$min_width: 320px;
$max_width: 1920px;

$distance-max-xsmall: var(--distance-max-xsmall);
$distance-max-small: var(--distance-max-small);
$distance-max-medium: var(--distance-max-medium);
$distance-max-large: var(--distance-max-large);
$distance-max-xlarge: var(--distance-max-xlarge);
$distance-min-xsmall: var(--distance-min-xsmall);
$distance-min-small: var(--distance-min-small);
$distance-min-medium: var(--distance-min-medium);
$distance-min-large: var(--distance-min-large);
$distance-min-xlarge: var(--distance-min-xlarge);

$color-dark-1: var(--color-dark-1);
$color-dark-2: var(--color-dark-2);
$color-dark-3: var(--color-dark-3);
$color-dark-4: var(--color-dark-4);

$color-light-1: var(--color-light-1);
$color-light-2: var(--color-light-2);
$color-light-3: var(--color-light-3);
$color-light-4: var(--color-light-4);

$color-gray-1: var(--color-gray-1);
$color-gray-2: var(--color-gray-2);
$color-gray-3: var(--color-gray-3);
$color-gray-4: var(--color-gray-4);

$color-border-dark: var(--color-border-dark);
$color-border-light: var(--color-border-light);

$color-portfolio-1: var(--color-portfolio-1);
$color-portfolio-2: var(--color-portfolio-2);
$color-portfolio-3: var(--color-portfolio-3);
$color-portfolio-4: var(--color-portfolio-4);
$color-portfolio-5: var(--color-portfolio-5);
$color-portfolio-6: var(--color-portfolio-6);
$color-portfolio-7: var(--color-portfolio-7);
$color-portfolio-8: var(--color-portfolio-8);
$color-portfolio-9: var(--color-portfolio-9);
$color-portfolio-10: var(--color-portfolio-10);
$color-portfolio-11: var(--color-portfolio-11);
$color-portfolio-12: var(--color-portfolio-12);

html {
	font-size: $em-base;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeSpeed;
	-webkit-text-rendering: optimizeSpeed;
	-moz-osx-font-smoothing: grayscale;
	font-kerning: none;
	scroll-behavior: auto !important; }
html.smoothscroll {
	body {
		overflow-y: hidden !important;
		overflow-x: hidden !important; } }
body {
	width: 100%;
	min-width: 320px;
	font-family: var(--font-primary);
	line-height: var(--paragraph-line-height);
	color: var(--paragraph-color-dark);
	font-size: 100%;
	word-wrap: break-word;
	overflow-wrap: anywhere;
	overflow-x: hidden; }
img, iframe {
	max-width: 100%; }
img {
	height: auto; }
.page-wrapper__content {
	position: relative;
	overflow: hidden;
	z-index: 50; }
a {
	text-decoration: none;
	color: $color-gray-3;
	@include trans1;
	&:hover, &:focus {
		text-decoration: none; }
	&:hover {
		color: $color-dark-1; } }
.body_lock-scroll {
	overflow: hidden;
	position: fixed;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
.container-fluid {
	padding-left: var(--gutter-horizontal, 120);
	padding-right: var(--gutter-horizontal, 120); }

.container-fluid_paddings {
	padding-top: var(--gutter-horizontal, 120);
	padding-bottom: var(--gutter-horizontal, 120); }

.container-fluid_default {
	padding-left: 20px;
	padding-right: 20px; }
.arts-is-dragging {
	cursor: none;
	// pointer-events: none !important
	// .pointer-events-auto, .slider-projects__slide.swiper-slide-active
	// 	pointer-events: none !important
	// 	*
	// 		pointer-events: none !important
	// *
 }	// 	pointer-events: none !important
#page-wrapper {
	overflow-x: hidden;
	background-color: #fff; }

@media screen and (max-width: $md) {
	.container_p-md-0 {
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: 0; }

	.container_px-md-0 {
		padding-left: 0;
		padding-right: 0;
		> .row {
			margin-left: 0;
			margin-right: 0;
			> [class*="col-"] {
				padding-left: 0;
				padding-right: 0; } } }

	.container_py-md-0 {
		padding-top: 0;
		padding-bottom: 0; } }

@media screen and (max-width: $sm) {
	.container_p-sm-0 {
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: 0; }

	.container_px-sm-0 {
		padding-left: 0;
		padding-right: 0;
		> .row {
			margin-left: 0;
			margin-right: 0;
			> [class*="col-"] {
				padding-left: 0;
				padding-right: 0; } } }

	.container_py-sm-0 {
		padding-top: 0;
		padding-bottom: 0; } }

@media screen and (max-width: $xs) {
	.container_p-xs-0 {
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: 0; }

	.container_px-xs-0 {
		padding-left: 0;
		padding-right: 0;
		> .row {
			margin-left: 0;
			margin-right: 0;
			> [class*="col-"] {
				padding-left: 0;
				padding-right: 0; } } }
	.container_py-xs-0 {
		padding-top: 0;
		padding-bottom: 0; } }

 :root {
	/* Bootstrap */
	--bs-gutter-x: 20px;
	--bs-gutter-y: 20px;
	/* Fonts */
	--font-primary: 'Inter', sans-serif;
	/* Dark Colors */
	--color-dark-1: #000000;
	--color-dark-2: #181818;
	--color-dark-3: #333333;
	--color-dark-4: #555555;
	/* Light Colors */
	--color-light-1: #ffffff;
	--color-light-2: #fafafa;
	--color-light-3: #f8f8f8;
	--color-light-4: #f2f2ed;
	--color-gray-1: #b0b0b0;
	--color-gray-2: #cccccc;
	--color-gray-3: #888888;
	--color-gray-4: #e8e8e8;
	/* Portfolio Projects Colors */
	--color-portfolio-1: #f1f6f6;
	--color-portfolio-2: #262626;
	--color-portfolio-3: #f6f6f1;
	--color-portfolio-4: #d7d7d9;
	--color-portfolio-5: #dededb;
	--color-portfolio-6: #f7f6f3;
	--color-portfolio-7: #f0f0f0;
	--color-portfolio-8: #eaeaea;
	--color-portfolio-9: #f1f0f0;
	--color-portfolio-10: #eeece6;
	--color-portfolio-11: #fbf7f6;
	--color-portfolio-12: #e9eaec;
	/* Borders Colors */
	--color-border-dark: rgba(136, 136, 136, .3);
	--color-border-light: rgba(136, 136, 136, .3);
	/* XXL heading */
	--xxl-max-font-size: 126;
	--xxl-min-font-size: 54;
	--xxl-line-height: 1.0;
	--xxl-letter-spacing: -4;
	--xxl-color-dark: #555555;
	--xxl-color-light: #ffffff;
	/* XL heading */
	--xl-max-font-size: 102;
	--xl-min-font-size: 46;
	--xl-line-height: 1.18;
	--xl-letter-spacing: -2;
	--xl-color-dark: #555555;
	--xl-color-light: #ffffff;
	/* h1 heading */
	--h1-max-font-size: 80;
	--h1-min-font-size: 40;
	--h1-line-height: 1.20;
	--h1-letter-spacing: -1;
	--h1-color-dark: #555555;
	--h1-color-light: #ffffff;
	/* h2 heading */
	--h2-max-font-size: 52;
	--h2-min-font-size: 30;
	--h2-line-height: 1.28;
	--h2-letter-spacing: 0;
	--h2-color-dark: #555555;
	--h2-color-light: #ffffff;
	/* h3 heading */
	--h3-max-font-size: 32;
	--h3-min-font-size: 24;
	--h3-line-height: 1.5;
	--h3-letter-spacing: 0;
	--h3-color-dark: #555555;
	--h3-color-light: #ffffff;
	/* h4 heading */
	--h4-max-font-size: 20;
	--h4-min-font-size: 18;
	--h4-line-height: 1.8;
	--h4-letter-spacing: 0;
	--h4-color-dark: #555555;
	--h4-color-light: #ffffff;
	/* h5 heading */
	--h5-max-font-size: 18;
	--h5-min-font-size: 16;
	--h5-line-height: 1.8;
	--h5-letter-spacing: 0;
	--h5-color-dark: #555555;
	--h5-color-light: #ffffff;
	/* h6 heading */
	--h6-max-font-size: 16;
	--h6-min-font-size: 14;
	--h6-line-height: 1.8;
	--h6-letter-spacing: 0;
	--h6-color-dark: #555555;
	--h6-color-light: #ffffff;
	/* Subheading */
	--subheading-max-font-size: 13;
	--subheading-min-font-size: 10;
	--subheading-line-height: 1.0;
	--subheading-letter-spacing: 2;
	--subheading-color-dark: #555555;
	--subheading-color-light: #b0b0b0;
	/* Paragraph */
	--paragraph-max-font-size: 16;
	--paragraph-min-font-size: 16;
	--paragraph-line-height: 1.8;
	--paragraph-letter-spacing: 0;
	--paragraph-color-dark: #555555;
	--paragraph-color-light: #ffffff;
	/* Blockquote */
	--blockquote-max-font-size: 24;
	--blockquote-min-font-size: 16;
	--blockquote-letter-spacing: 0;
	--blockquote-color-dark: #555555;
	--blockquote-color-light: #ffffff;
	/* Dropcap */
	--dropcap-max-font-size: 90;
	--dropcap-min-font-size: 60;
	--dropcap-color-dark: #111111;
	--dropcap-color-light: #ffffff;
	/* Fluid paddings & margins (min values) */
	--distance-min-xsmall: 30;
	--distance-min-small: 40;
	--distance-min-medium: 50;
	--distance-min-large: 50;
	--distance-min-xlarge: 90;
	/* Fluid paddings & margins (max values) */
	--distance-max-xsmall: 60;
	--distance-max-small: 80;
	--distance-max-medium: 100;
	--distance-max-large: 160;
	--distance-max-xlarge: 240;
	/* Container & page gutters */
	--gutter-horizontal: 120px;
	--gutter-vertical: 100px;
	@media screen and (max-width: $xxl) {
		--gutter-horizontal: 80px;
		--gutter-vertical: 60px; }
	@media screen and (max-width: $xl) {
		--gutter-horizontal: 60px;
		--gutter-vertical: 40px; }
	@media screen and (max-width: $md) {
		--gutter-horizontal: 20px;
		--gutter-vertical: 20px; } }

/* ======================================================================== */
/* 1. bootstrap */
/* ======================================================================== */
// .row
//   --bs-gutter-x: 40px

@media (min-width: 1400px) {
	.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
		max-width: 1140px; } }

/* ======================================================================== */
/* 2. button */
/* ======================================================================== */
.button {
	position: relative;
	display: inline-block;
	font-size: 13px;
	line-height: 1;
	font-weight: 500;
	white-space: nowrap;
	cursor: pointer;
	box-shadow: none;
	border-width: 2px;
	border-style: solid;
	padding: 18px 42px;
	border-radius: 96px;
	color: $color-dark-4;
	@include trans1;
	transition-property: color, background-color, border-color;
	&:focus {
		outline: none; }
	&:hover {
		.button__label-normal {
			.button__title {
				transform: translate(0%, -100%);
				opacity: 0; }
			.button__icon {
				opacity: 0;
				transform: translate(100%, 0%); } }
		.button__label-hover {
			.button__title {
				transform: translate(0%, 0%);
				opacity: 1; }
			.button__icon {
				opacity: 1;
				transform: translate(0%, 0%); } } } }

.button_icon {
	display: inline-flex;
	border: none;
	.button__label {
		padding: 0; } }
.button__label-hover {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	.button__title {
		transform: translate(0%, 100%);
		opacity: 0; }
	.button__icon {
		transform: translate(-100%, 0%);
		opacity: 0; } }

.button__label-normal {
	.button__title {
		transform: translate(0%, 0%);
		opacity: 1; } }

.button__title {
	display: inline-block;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; }
.button__label {
	display: block;
	padding: 18px 42px; }

.button__icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	// width: 60px
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
	font-size: 75%; }
.button__icon_before {
	margin-right: 0.5em; }
.button__icon_after {
	margin-left: 0.25em; }

.button_fullwidth {
	width: 100%; }
.button-fullwidth {
	.button {
		width: 100%; } }

@media screen and (max-width: $xl) {
	.button {
		padding: 16px 32px; }
	.button__label {
		padding: 16px 32px; } }

@media screen and (max-width: $md) {
	.button {
		padding: 16px 30px; }
	.button__label {
		padding: 16px 30px; }
	.button__icon {
		// width: 43px
		i {
			font-size: 20px; } } }

/* ======================================================================== */
/* 3. buttonBordered */
/* ======================================================================== */
.button.button_bordered {
	background-color: unset !important;
	color: $color-dark-1;
	border-width: 1px;
	border-style: solid;
	// &.bg-dark-1
	// 	border-color: $color-dark-1
	// 	// &:hover
	// 	// 	border-color: $color-dark-2
	// 	// 	color: $color-dark-2
	// &.bg-dark-2
	// 	border-color: $color-dark-2
	// &.bg-dark-3
	// 	border-color: $color-dark-3
	// &.bg-dark-4
	// 	border-color: $color-dark-4
	// &.bg-light-1
	// 	border-color: $color-light-1
	// &.bg-light-2
	// 	border-color: $color-light-2
	// &.bg-light-3
	// 	border-color: $color-light-3
	// &.bg-light-4
	// 	border-color: $color-light-4
	// &.bg-white-1
	// 	border-color: $color-border-light
	// 	color: #fff
	// 	&:hover
	// 		border-color: #fff
	// &.bg-gray-1
	// 	border-color: $color-gray-1
	// &.bg-gray-2
	// 	border-color: $color-gray-2
	// &.bg-gray-3
	// 	border-color: $color-gray-3
	// &:hover
	// 	border-color: $color-dark-1
 }	// 	color: $color-dark-1

/* ======================================================================== */
/* 4. buttonSolid */
/* ======================================================================== */
.button.button_solid {
	border-color: transparent;
	color: $color-gray-3;
	&.bg-dark-1 {
		color: #fff;
		background-color: $color-dark-1;
		// &:hover
 }		// 	background-color: $color-dark-3
	&.bg-dark-2 {
		color: #fff;
		background-color: $color-dark-2; }
	&.bg-dark-3 {
		color: #fff;
		background-color: $color-dark-3; }
	&.bg-dark-4 {
		color: #fff;
		background-color: $color-dark-4; }
	&.bg-light-1 {
		background-color: $color-light-1;
		color: $color-dark-1; }
	&.bg-light-2 {
		background-color: $color-light-2;
		color: $color-dark-1; }
	&.bg-light-3 {
		background-color: $color-light-3;
		color: $color-dark-1; }
	&.bg-light-4 {
		background-color: $color-light-4;
		color: $color-dark-1; }
	&.bg-white-1 {
		background-color: $color-border-light;
		color: $color-dark-1;
		// &:hover
 }		// 	background-color: $color-gray-1
	&.bg-gray-1 {
		background-color: $color-gray-1; }
	&.bg-gray-2 {
		background-color: $color-gray-2; }
	&.bg-gray-3 {
		background-color: $color-gray-3; }
	// &:hover
	// 	background-color: $color-dark-1
 }	// 	color: #fff

/* ======================================================================== */
/* 5. buttonCircle */
/* ======================================================================== */
.button-circle {
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  @include fluid('width', 60, 90);
  @include fluid('height', 60, 90);
  padding: 0 !important;
  opacity: .85;
  transition-property: opacity, color, background-color, border-color;
  &:hover {
    opacity: 1; } }
.cursorfollower {
  .button-circle[data-arts-cursor] {
    &:hover {
      border-color: transparent !important; } } }
.button-circle_medium {
  @include fluid('width', 90, 140);
  @include fluid('height', 90, 140); }
.button-circle_big {
  @include fluid('width', 130, 160);
  @include fluid('height', 130, 160); }
.button-circle__inner {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
.button-circle__geometry {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid $color-border-dark;
  border-radius: 50%; }
.button-circle__arrow {
  width: 30px;
  @include fluid-type(12, 18);
  &:before {
    width: 30px;
    margin: 0; } }
.button-circle__label {
  padding: 0 !important; }

/* ======================================================================== */
/* 6. card */
/* ======================================================================== */
.card {
  width: 100%;
  height: 100%;
  position: relative; }
.card__content {
  ul, ol {
    padding-left: 0 !important; } }
.card__counter {
  position: absolute;
  right: 40px;
  bottom: 30px; }
.card__arrow {
  margin-left: 1rem; }


@media screen and (max-width: $md) {
  .card__counter {
    position: absolute;
    right: 25px;
    bottom: 15px; } }

/* ======================================================================== */
/* 7. buttonCircles */
/* ======================================================================== */
.button-circles {
	display: inline-flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 30px;
	height: 30px;
	padding: 0 !important;
	&.block-counter {
		margin-left: 4px; }
	.block-counter__counter {
		top: -12px;
		left: calc(100% + 6px); } }
.button-circles__circle {
	position: relative;
	width: 12px;
	height: 12px;
	outline: none;
	display: inline-block;
	border: 2px solid $color-border-dark;
	vertical-align: middle;
	border-radius: 100%;
	&:nth-child(1) {
		align-self: flex-start; }
	&:nth-child(2) {
		align-self: flex-start; }
	&:nth-child(3) {
		align-self: flex-end; }
	&:nth-child(4) {
		align-self: flex-end; }
	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: auto;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		stroke-width: 12px;
		z-index: 50;
		.circle {
			stroke: #fff; } } }

@media screen and (max-width: $sm) {
	.button-circles {
		width: 30px;
		height: 30px; }
	.button-circles__circle {
		width: 12px;
		height: 12px;
		svg {
			width: 12px;
			height: 12px; } } }

/* ======================================================================== */
/* 8. cardPortfolio */
/* ======================================================================== */
.card-portfolio {
	overflow: hidden;
	border-radius: 4px; }
.card-portfolio__content {
  // padding: 4em
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
.card-portfolio__content_small {
  padding: 60px; }
.card-portfolio__wrapper-img {
  img {
    transform: scale(1.03); } }

// @media screen and (max-width: $lg)
//   .card-portfolio__content
//     padding: 3em

/* ======================================================================== */
/* 9. categories */
/* ======================================================================== */
.categories {
	@include reset-ul;
	font-size: 13px;
	font-weight: 500;
	li {
		display: inline-flex;
		flex-wrap: wrap;
		// align-items: center
		line-height: 1.6; }
	li:not(:last-child):after {
		content: '/';
		color: $color-dark-1;
		display: inline-block;
		margin-left: 6px;
		margin-right: 5px;
		@include trans1; } }

/* ======================================================================== */
/* 10. colors */
/* ======================================================================== */
.bg-dark-1 {
  background-color: $color-dark-1; }
.bg-dark-2 {
  background-color: $color-dark-2; }
.bg-dark-3 {
  background-color: $color-dark-3; }
.bg-dark-4 {
  background-color: $color-dark-4; }

.bg-light-1 {
  background-color: $color-light-1; }
.bg-light-2 {
  background-color: $color-light-2; }
.bg-light-3 {
  background-color: $color-light-3; }
.bg-light-4 {
  background-color: $color-light-4; }

.bg-white-1 {
  background-color: #fff; }

.bg-gray-1 {
  background-color: $color-gray-1; }
.bg-gray-2 {
  background-color: $color-gray-2; }
.bg-gray-3 {
  background-color: $color-gray-3; }
.bg-gray-4 {
  background-color: $color-gray-4; }

.bg-portfolio-1 {
  background-color: $color-portfolio-1; }
.bg-portfolio-2 {
  background-color: $color-portfolio-2; }
.bg-portfolio-3 {
  background-color: $color-portfolio-3; }
.bg-portfolio-4 {
  background-color: $color-portfolio-4; }
.bg-portfolio-5 {
  background-color: $color-portfolio-5; }
.bg-portfolio-6 {
  background-color: $color-portfolio-6; }
.bg-portfolio-7 {
  background-color: $color-portfolio-7; }
.bg-portfolio-8 {
  background-color: $color-portfolio-8; }
.bg-portfolio-9 {
  background-color: $color-portfolio-9; }
.bg-portfolio-10 {
  background-color: $color-portfolio-10; }
.bg-portfolio-11 {
  background-color: $color-portfolio-11; }
.bg-portfolio-12 {
  background-color: $color-portfolio-12; }

/* ======================================================================== */
/* 11. counter */
/* ======================================================================== */
.counter_circle {
	position: relative;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	// width: 100%
	// height: 0
	// padding-bottom: 100%
	@include fluid('width', 220, 260);
	@include fluid('height', 220, 260);
	// width: 280px
	// height: 280px
	// max-width: 100%
	// max-height: 100%
	.svg-circle {
		stroke-width: 2px;
		stroke: $color-border-dark;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0; } }
// .counter__inner
// 	display: flex
// 	position: absolute
// 	top: 0
// 	left: 0
// 	width: 100%
// 	height: 100%
// 	flex-direction: column
// 	align-items: center
// 	justify-content: center
.counter__number {
	position: relative;
	z-index: 50;
	// font-family: $font-secondary
	// @include fluid-type(43, 104)
	// font-weight: 200
 }	// line-height: 1

.counter__label {
	position: relative;
	z-index: 50;
	margin-top: 8px;
	white-space: nowrap;
	// color: $brown-grey
	line-height: 1.2 !important;
 }	// @include fluid-type(14, 18)

/* ======================================================================== */
/* 12. cursor */
/* ======================================================================== */
.arts-cursor {
	color: #f27461;
	display: none; }
.arts-cursor__stroke-inner, .arts-cursor__stroke-outer {
	stroke-width: 2px;
	stroke: #ed4830; }
.arts-cursor__label {
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0; }
.cursor-progress {
	cursor: progress !important;
	* {
		cursor: progress !important; } }
.cursor-none {
	cursor: none !important;
	* {
		cursor: none !important; } }
.is-dragging {
	cursor: none !important;
	* {
		cursor: none !important; }
	.is-dragging__blocker {
		visibility: visible;
		opacity: 1;
		z-index: 100; } }
.is-dragging__blocker {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	transition all 0.2s ease {}
	transition-delay: 0.2s; }

// .arts-cursor-none
// 	[data-arts-cursor]
// 		pointer-events: none !important

/* ======================================================================== */
/* 13. backgroundHover */
/* ======================================================================== */
.background-hover {
	position: relative;
	display: block;
	&:hover {
		*, *:after, *:before {
			color: #fff !important; }
		a {
			opacity: 0.9;
			&:hover {
				opacity: 1; } }
		.background-hover__image {
			opacity: 1; }
		.background-hover__overlay {
			opacity: 1; } }
	.background-hover__overlay {
		opacity: 0;
		@include trans1; } }
.background-hover__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .01;
	@include trans1; }
.background-hover__content {
	position: relative;
	z-index: 50; }
.background-hover__overlay {
	z-index: 10 !important; }

/* ======================================================================== */
/* 14. hoverZoom */
/* ======================================================================== */
.hover-zoom {
	backface-visibility: hidden; }
.hover-zoom {
	&:hover:not(:focus) {
		@include hoverZoomEffect; } }
.hover-zoom_out {
	&:hover {
		.hover-zoom__zoom {
			transform: scale(1) !important; }
		.hover-zoom__inner {
			transform: scale(0.95) !important; } } }
.hover-zoom__inner {
	overflow: hidden;
	height: 100%;
	width: 100%; }
.hover-zoom__zoom, .hover-zoom__inner {
	transform-origin: center center;
	@include trans3;
	will-change: transform;
	backface-visibility: hidden; }
.hover-zoom__caption {
	@include trans3; }
.hover-zoom__zoomed {
	transform: scale(1.1); }

/* ======================================================================== */
/* 15. hoverZoomUnderline */
/* ======================================================================== */
.hover-zoom-underline {
  @include hoverZoomEffect;
  @include hoverUnderlineEffect; }

/* ======================================================================== */
/* 16. maskReveal */
/* ======================================================================== */
.mask-reveal {
  position: relative;
  overflow: hidden; }
.mask-reveal__layer {
  display: block;
  // position: absolute
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

/* ======================================================================== */
/* 17. underline */
/* ======================================================================== */
.underline, .underline-hover-self {
	display: inline;
	position: relative;
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 0% 100%;
	background-repeat: no-repeat;
	background-size: 0% 2px;
	.arts-split-text__line {
		display: inline-block !important;
		background-image: linear-gradient(currentColor, currentColor);
		background-position: 0% 100%;
		background-repeat: no-repeat;
		background-size: 0% 2px;
		transition: background-size .4s ease; } }
.underline-hover-self {
	background-position: 100% 100%;
	background-size: 0% 2px;
	transition: background-size .4s ease, color 0.2s ease-in-out;
	&:hover {
		background-position: 0% 100%;
		background-size: 100% 2px;
		// .arts-split-text__line
		// 	background-position: 0% 100%
 } }		// 	background-size: 100% 2px
.underline-hover {
	&:hover {
		.underline-hover__target {
			background-position: 0% 100%;
			background-size: 100% 2px;
			.arts-split-text__line {
				background-position: 0% 100%;
				background-size: 100% 2px; } } } }
.underline-hover__target:not(.arts-split-text) {
	display: inline;
	position: relative;
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-size: 0% 2px;
	transition: background-size .4s ease; }
.underline-hover__target {
	.arts-split-text__line {
		display: inline-block !important;
		background-image: linear-gradient(currentColor, currentColor);
		background-position: 100% 100%;
		background-repeat: no-repeat;
		background-size: 0% 2px;
		transition: background-size .4s ease; } }
.is-ajax-loading {
	.underline, .underline-hover-self {
		background-position: 100% 100% !important;
		background-size: 0% 2px !important;
		.arts-split-text__line {
			background-position: 100% 100% !important;
			background-size: 0% 2px !important; } }
	.underline-hover__target:not(.arts-split-text) {
		background-position: 100% 100% !important;
		background-size: 0% 2px !important; }
	.underline-hover__target {
		background-position: 100% 100% !important;
		background-size: 0% 2px !important;
		.arts-split-text__line {
			background-position: 100% 100% !important;
			background-size: 0% 2px !important; } } }

/* ======================================================================== */
/* 18. feature */
/* ======================================================================== */
.feature {}
.feature__wrapper-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include fluid('width', 120, 220);
  @include fluid('height', 120, 220);
  // width: 220px
  // height: 220px
  border: 1px solid $color-border-dark;
  border-radius: 50%;
  padding: 30px; }
.feature__header {
  display: block;
  width: 100%; }
.feature__subheading {
  color: var(--color-gray-3); }

/* ======================================================================== */
/* 19. figureCategory */
/* ======================================================================== */
.figure-category {
	position: relative;
	display: flex;
	height: 0;
	Width: 100%;
	padding-bottom: 100%; }
.figure-category__icon {
	@include fluid-type(26, 64); }
.figure-category__link {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
	border-radius: 100%;
	border: 1px solid $color-border-dark;
	transition-property: opacity, color, background-color, border-color;
	&:hover {
		border-color: $color-dark-1; } }
.cursorfollower {
	.figure-category__link[data-arts-cursor] {
		&:hover {
			border-color: transparent; } } }

/* ======================================================================== */
/* 20. figureImage */
/* ======================================================================== */
.figure-image {
	display: block;
	margin-bottom: 0; }
.figure-image__wrapper-img {
	position: relative;
	overflow: hidden; }
.figure-image__caption {
	display: inline-block !important; }

/* ======================================================================== */
/* 21. figureMember */
/* ======================================================================== */
.figure-member_has-social {
	&:hover {
		.figure-member__avatar img {
			transform: scale(1.1); }
		.figure-member__headline {
			width: 80px; }
		.figure-member__position {
			transition-delay: 50ms;
			transform: translateY(-30px);
			opacity: 0;
			visibility: hidden; }
		.figure-member__footer {
			.figure-member__name {
				transform: translateY(-20px);
				opacity: 0;
				visibility: hidden;
				transition-delay: 0ms; } }
		.figure-member__social {
			li a {
				transform: translateY(0px);
				opacity: 1;
				visibility: visible; }
			li {
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						a {
							transition-delay: 50ms + $i * 20ms; } } } } } } }

.figure-member__avatar {
	overflow: hidden;
	img {
		@include trans1; } }
.figure-member__footer {
	position: relative; }
.figure-member__name {
	margin-top: 0;
	margin-bottom: 0;
	@include trans1;
	transition-delay: 150ms; }
.figure-member__position {
	// margin-top: 0.5em
	@include trans1;
	transition-delay: 150ms; }
.figure-member__headline {
	display: inline-block;
	width: 60px;
	height: 1px;
	vertical-align: middle;
	background-color: var(--color-gray-1);
	@include trans1; }
.figure-member__social {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	li a {
		transform: translateY(30px);
		@include trans1;
		opacity: 0;
		visibility: hidden;
		&:last-child {
			margin-right: 0; } } }

/* ======================================================================== */
/* 22. figureProject */
/* ======================================================================== */
.figure-project {
	width: 100%; }
.figure-project__wrapper-img {
	display: block;
	img {
		transform: scale(1.1); } }

/* ======================================================================== */
/* 23. filter */
/* ======================================================================== */
.filter {
	position: relative; }
.filter__inner {
	margin-top: -0.5em;
	margin-bottom: -0.5em;
	position: relative; }
.filter__item {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	cursor: pointer;
	@include trans1;
	color: var(--color-dark-4);
	opacity: 0.5;
	&:not(.filter__item_active):hover {
		opacity: 1; } }
.filter__item-inner {
	position: relative;
	display: inline-block; }
.filter__item_active {
	opacity: 1;
	pointer-events: none; }
.filter__underline {
	pointer-events: none;
	display: block;
	position: absolute;
	margin-top: 2em;
	top: 0;
	left: 0;
	background-color: var(--color-dark-4);
	width: 0;
	height: 2px; }
.filter__item-label {
	display: inline-block; }

@media screen and (max-width: $md) {
	.filter {
		.filter__item {
			&:first-child {
				padding-top: 0; }
			&:last-child {
				padding-bottom: 0; } } } }

/* ======================================================================== */
/* 24. form */
/* ======================================================================== */
.form {
	width: 100%; }
.form__submit {
	margin-top: 1.5em; }
.form__col {
	margin-top: 0.5em;
	margin-bottom: 0.5em; }
.form__col_submit {
	margin-top: 1.5em;
	margin-bottom: 0; }
.form__error, span.wpcf7-not-valid-tip {
	display: block;
	font-size: 12px;
	color: red;
	text-align: left;
	margin-top: 4px; }
.form__heading {
	margin-top: 0;
	margin-bottom: 1em; }

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
	border-color: red;
	padding: 15px;
	margin: 1.5em 0 0;
	display: none !important; }

@media only screen and (max-width: 992px) {
	.form__col_submit {
		text-align: left; } }

/* ======================================================================== */
/* 25. fluidMargins */
/* ======================================================================== */
// Margin All Sides
.m-xsmall {
	@include fluid('margin', $distance-min-xsmall, $distance-max-xsmall); }
.m-small {
	@include fluid('margin', $distance-min-small, $distance-max-small); }
.m-medium {
	@include fluid('margin', $distance-min-medium, $distance-max-medium); }
.m-large {
	@include fluid('margin', $distance-min-large, $distance-max-large); }
.m-xlarge {
	@include fluid('margin', $distance-min-xlarge, $distance-max-xlarge); }

// Margin Vertical
.my-xsmall {
	@include fluid('margin-top', $distance-min-xsmall, $distance-max-xsmall);
	@include fluid('margin-bottom', $distance-min-xsmall, $distance-max-xsmall); }
.my-small {
	@include fluid('margin-top', $distance-min-small, $distance-max-small);
	@include fluid('margin-bottom', $distance-min-small, $distance-max-small); }
.my-medium {
	@include fluid('margin-top', $distance-min-medium, $distance-max-medium);
	@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium); }
.my-large {
	@include fluid('margin-top', $distance-min-large, $distance-max-large);
	@include fluid('margin-bottom', $distance-min-large, $distance-max-large); }
.my-xlarge {
	@include fluid('margin-top', $distance-min-xlarge, $distance-max-xlarge);
	@include fluid('margin-bottom', $distance-min-xlarge, $distance-max-xlarge); }

// Margin Horizontal
.mx-xsmall {
	@include fluid('margin-left', $distance-min-xsmall, $distance-max-xsmall);
	@include fluid('margin-right', $distance-min-xsmall, $distance-max-xsmall); }
.mx-small {
	@include fluid('margin-left', $distance-min-small, $distance-max-small);
	@include fluid('margin-right', $distance-min-small, $distance-max-small); }
.mx-medium {
	@include fluid('margin-left', $distance-min-medium, $distance-max-medium);
	@include fluid('margin-right', $distance-min-medium, $distance-max-medium); }
.mx-large {
	@include fluid('margin-left', $distance-min-large, $distance-max-large);
	@include fluid('margin-right', $distance-min-large, $distance-max-large); }
.mx-xlarge {
	@include fluid('margin-left', $distance-min-xlarge, $distance-max-xlarge);
	@include fluid('margin-right', $distance-min-xlarge, $distance-max-xlarge); }

// Margin Top
.mt-xsmall {
	@include fluid('margin-top', $distance-min-xsmall, $distance-max-xsmall); }
.mt-small {
	@include fluid('margin-top', $distance-min-small, $distance-max-small); }
.mt-medium {
	@include fluid('margin-top', $distance-min-medium, $distance-max-medium); }
.mt-large {
	@include fluid('margin-top', $distance-min-large, $distance-max-large); }
.mt-xlarge {
	@include fluid('margin-top', $distance-min-xlarge, $distance-max-xlarge); }

// Margin Right
.mr-xsmall {
	@include fluid('margin-right', $distance-min-xsmall, $distance-max-xsmall); }
.mr-small {
	@include fluid('margin-right', $distance-min-small, $distance-max-small); }
.mr-medium {
	@include fluid('margin-right', $distance-min-medium, $distance-max-medium); }
.mr-large {
	@include fluid('margin-right', $distance-min-large, $distance-max-large); }
.mr-xlarge {
	@include fluid('margin-right', $distance-min-xlarge, $distance-max-xlarge); }

// Margin Bottom
.mb-xsmall {
	@include fluid('margin-bottom', $distance-min-xsmall, $distance-max-xsmall); }
.mb-small {
	@include fluid('margin-bottom', $distance-min-small, $distance-max-small); }
.mb-medium {
	@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium); }
.mb-large {
	@include fluid('margin-bottom', $distance-min-large, $distance-max-large); }
.mb-xlarge {
	@include fluid('margin-bottom', $distance-min-xlarge, $distance-max-xlarge); }

// Margin Left
.ml-xsmall {
	@include fluid('margin-left', $distance-min-xsmall, $distance-max-xsmall); }
.ml-small {
	@include fluid('margin-left', $distance-min-small, $distance-max-small); }
.ml-medium {
	@include fluid('margin-left', $distance-min-medium, $distance-max-medium); }
.ml-large {
	@include fluid('margin-left', $distance-min-large, $distance-max-large); }
.ml-xlarge {
	@include fluid('margin-left', $distance-min-xlarge, $distance-max-xlarge); }

/* ======================================================================== */
/* 26. fluidMarginsNegative */
/* ======================================================================== */
// Margin Top Negative
.mt-minus-xsmall {
	@include fluid('margin-top', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1); }
.mt-minus-small {
	@include fluid('margin-top', $distance-min-small, $distance-max-small, $min_width, $max_width, -1); }
.mt-minus-medium {
	@include fluid('margin-top', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1); }
.mt-minus-large {
	@include fluid('margin-top', $distance-min-large, $distance-max-large, $min_width, $max_width, -1); }
.mt-minus-xlarge {
	@include fluid('margin-top', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1); }

// Margin Right Negative
.mr-minus-xsmall {
	@include fluid('margin-right', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1); }
.mr-minus-small {
	@include fluid('margin-right', $distance-min-small, $distance-max-small, $min_width, $max_width, -1); }
.mr-minus-medium {
	@include fluid('margin-right', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1); }
.mr-minus-large {
	@include fluid('margin-right', $distance-min-large, $distance-max-large, $min_width, $max_width, -1); }
.mr-minus-xlarge {
	@include fluid('margin-right', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1); }

// Margin Bottom Negative
.mb-minus-xsmall {
	@include fluid('margin-bottom', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1); }
.mb-minus-small {
	@include fluid('margin-bottom', $distance-min-small, $distance-max-small, $min_width, $max_width, -1); }
.mb-minus-medium {
	@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1); }
.mb-minus-large {
	@include fluid('margin-bottom', $distance-min-large, $distance-max-large, $min_width, $max_width, -1); }
.mb-minus-xlarge {
	@include fluid('margin-bottom', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1); }

// Margin Left Negative
.ml-minus-xsmall {
	@include fluid('margin-left', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1); }
.ml-minus-small {
	@include fluid('margin-left', $distance-min-small, $distance-max-small, $min_width, $max_width, -1); }
.ml-minus-medium {
	@include fluid('margin-left', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1); }
.ml-minus-large {
	@include fluid('margin-left', $distance-min-large, $distance-max-large, $min_width, $max_width, -1); }
.ml-minus-xlarge {
	@include fluid('margin-left', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1); }

/* ======================================================================== */
/* 27. fluidPaddings */
/* ======================================================================== */
// Padding All Sides
.p-xsmall {
	@include fluid('padding', $distance-min-xsmall, $distance-max-xsmall); }
.p-small {
	@include fluid('padding', $distance-min-small, $distance-max-small); }
.p-medium {
	@include fluid('padding', $distance-min-medium, $distance-max-medium); }
.p-large {
	@include fluid('padding', $distance-min-large, $distance-max-large); }
.p-xlarge {
	@include fluid('padding', $distance-min-xlarge, $distance-max-xlarge); }

// Padding Vertical
.py-xsmall {
	@include fluid('padding-top', $distance-min-xsmall, $distance-max-xsmall);
	@include fluid('padding-bottom', $distance-min-xsmall, $distance-max-xsmall); }
.py-small {
	@include fluid('padding-top', $distance-min-small, $distance-max-small);
	@include fluid('padding-bottom', $distance-min-small, $distance-max-small); }
.py-medium {
	@include fluid('padding-top', $distance-min-medium, $distance-max-medium);
	@include fluid('padding-bottom', $distance-min-medium, $distance-max-medium); }
.py-large {
	@include fluid('padding-top', $distance-min-large, $distance-max-large);
	@include fluid('padding-bottom', $distance-min-large, $distance-max-large); }
.py-xlarge {
	@include fluid('padding-top', $distance-min-xlarge, $distance-max-xlarge);
	@include fluid('padding-bottom', $distance-min-xlarge, $distance-max-xlarge); }

// Padding Horizontal
.px-xsmall {
	@include fluid('padding-left', $distance-min-xsmall, $distance-max-xsmall);
	@include fluid('padding-right', $distance-min-xsmall, $distance-max-xsmall); }
.px-small {
	@include fluid('padding-left', $distance-min-small, $distance-max-small);
	@include fluid('padding-right', $distance-min-small, $distance-max-small); }
.px-medium {
	@include fluid('padding-left', $distance-min-medium, $distance-max-medium);
	@include fluid('padding-right', $distance-min-medium, $distance-max-medium); }
.px-large {
	@include fluid('padding-left', $distance-min-large, $distance-max-large);
	@include fluid('padding-right', $distance-min-large, $distance-max-large); }
.px-xlarge {
	@include fluid('padding-left', $distance-min-xlarge, $distance-max-xlarge);
	@include fluid('padding-right', $distance-min-xlarge, $distance-max-xlarge); }

// Padding Top
.pt-xsmall {
	@include fluid('padding-top', $distance-min-xsmall, $distance-max-xsmall); }
.pt-small {
	@include fluid('padding-top', $distance-min-small, $distance-max-small); }
.pt-medium {
	@include fluid('padding-top', $distance-min-medium, $distance-max-medium); }
.pt-large {
	@include fluid('padding-top', $distance-min-large, $distance-max-large); }
.pt-xlarge {
	@include fluid('padding-top', $distance-min-xlarge, $distance-max-xlarge); }

// Padding Right
.pr-xsmall {
	@include fluid('padding-right', $distance-min-xsmall, $distance-max-xsmall); }
.pr-small {
	@include fluid('padding-right', $distance-min-small, $distance-max-small); }
.pr-medium {
	@include fluid('padding-right', $distance-min-medium, $distance-max-medium); }
.pr-large {
	@include fluid('padding-right', $distance-min-large, $distance-max-large); }
.pr-xlarge {
	@include fluid('padding-right', $distance-min-xlarge, $distance-max-xlarge); }

// Padding Bottom
.pb-xsmall {
	@include fluid('padding-bottom', $distance-min-xsmall, $distance-max-xsmall); }
.pb-small {
	@include fluid('padding-bottom', $distance-min-small, $distance-max-small); }
.pb-medium {
	@include fluid('padding-bottom', $distance-min-medium, $distance-max-medium); }
.pb-large {
	@include fluid('padding-bottom', $distance-min-large, $distance-max-large); }
.pb-xlarge {
	@include fluid('padding-bottom', $distance-min-xlarge, $distance-max-xlarge); }

// Padding Left
.pl-xsmall {
	@include fluid('padding-left', $distance-min-xsmall, $distance-max-xsmall); }
.pl-small {
	@include fluid('padding-left', $distance-min-small, $distance-max-small); }
.pl-medium {
	@include fluid('padding-left', $distance-min-medium, $distance-max-medium); }
.pl-large {
	@include fluid('padding-left', $distance-min-large, $distance-max-large); }
.pl-xlarge {
	@include fluid('padding-left', $distance-min-xlarge, $distance-max-xlarge); }

/* ======================================================================== */
/* 28. fluidPaddingsOffsets */
/* ======================================================================== */
.offset_bottom {
	z-index: 60; }

.pt-small {
	&.offset_bottom {
		padding-top: 0;
		.section-offset__content, > .elementor-container {
			@include fluid-translate-y($distance-min-small, $distance-max-small, $min_width, $max_width); }
		&.mb-small {
			@include fluid('margin-bottom', $distance-min-small, $distance-max-small); }
		&.mb-medium {
			@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium); }
		&.mb-large {
			@include fluid('margin-bottom', $distance-min-large, $distance-max-large); } } }

.pt-medium {
	&.offset_bottom {
		padding-top: 0;
		.section-offset__content, > .elementor-container {
			@include fluid-translate-y($distance-min-medium, $distance-max-medium, $min_width, $max_width); }
		&.mb-small {
			@include fluid('margin-bottom', $distance-min-small, $distance-max-small); }
		&.mb-medium {
			@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium); }
		&.mb-large {
			@include fluid('margin-bottom', $distance-min-large, $distance-max-large); } } }

.pt-large {
	&.offset_bottom {
		padding-top: 0;
		.section-offset__content, > .elementor-container {
			@include fluid-translate-y($distance-min-medium, $distance-max-large, $min_width, $max_width); }
		&.mb-small {
			@include fluid('margin-bottom', $distance-min-small, $distance-max-small); }
		&.mb-medium {
			@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium); }
		&.mb-large {
			@include fluid('margin-bottom', $distance-min-large, $distance-max-large); } } }

.pb-small {
	&.offset_top {
		padding-bottom: 0;
		.section-offset__content, > .elementor-container {
			@include fluid-translate-y($distance-min-small, $distance-max-small, $min_width, $max_width, -1); }
		&.mt-small {
			@include fluid('margin-top', $distance-min-small, $distance-max-small); }
		&.mt-medium {
			@include fluid('margin-top', $distance-min-medium, $distance-max-medium); }
		&.mt-large {
			@include fluid('margin-top', $distance-min-large, $distance-max-large); } } }

.pb-medium {
	&.offset_top {
		padding-bottom: 0;
		.section-offset__content, > .elementor-container {
			@include fluid-translate-y($distance-min-medium, $distance-max-medium, $min_width, $max_width, -1); }
		&.mt-small {
			@include fluid('margin-top', $distance-min-small, $distance-max-small); }
		&.mt-medium {
			@include fluid('margin-top', $distance-min-medium, $distance-max-medium); }
		&.mt-large {
			@include fluid('margin-top', $distance-min-large, $distance-max-large); } } }

.pb-large {
	&.offset_top {
		padding-bottom: 0;
		.section-offset__content, > .elementor-container {
			@include fluid-translate-y($distance-min-large, $distance-max-large, $min_width, $max_width, -1); }
		&.mt-small {
			@include fluid('margin-top', $distance-min-small, $distance-max-small); }
		&.mt-medium {
			@include fluid('margin-top', $distance-min-medium, $distance-max-medium); }
		&.mt-large {
			@include fluid('margin-top', $distance-min-large, $distance-max-large); } } }

/* ======================================================================== */
/* 29. fluidTop */
/* ======================================================================== */
.top-offset {
  &.top-offset_xsmall {
    @include fluid('top', $distance-min-xsmall, $distance-max-xsmall); }
  &.top-offset_small {
    @include fluid('top', $distance-min-small, $distance-max-small); }
  &.top-offset_medium {
    @include fluid('top', $distance-min-medium, $distance-max-medium); }
  &.top-offset_large {
    @include fluid('top', $distance-min-large, $distance-max-large); }
  &.top-offset_xlarge {
    @include fluid('top', $distance-min-xlarge, $distance-max-xlarge); } }

/* ======================================================================== */
/* 30. gmap */
/* ======================================================================== */
.gmap {
	width: 100%;
	height: 100%; }
.gmap__container {
	width: 100%;
	height: 100%; }

// @media only screen and (max-width: $md)
// 	.gmap__container
// 		height: 600px
// 		max-height: 120vh

/* ======================================================================== */
/* 31. grid */
/* ======================================================================== */
.grid {
	overflow: hidden; }
.grid__sizer {
	padding: 0 !important;
	margin: 0 !important;
	height: 0 !important; }
.grid__item {
	display: block;
	width: 100%; }
.grid_filtered {
	.js-grid__item {
		height: auto !important; } }

@media screen and (max-width: $xl) {
	.grid {
		margin-left: auto;
		margin-right: auto; } }

@media only screen and (min-width: $md + 1px) {
	.grid__item_desktop-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.grid__item_desktop-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.grid__item_desktop-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }
	.grid__item_desktop-3 {
		flex: 0 0 25%;
		max-width: 25%; } }

@media only screen and (min-width: $sm + 1px) and (max-width: $md) {
	.grid__item_tablet-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.grid__item_tablet-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.grid__item_tablet-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }
	.grid__item_tablet-3 {
		flex: 0 0 25%;
		max-width: 25%; } }

@media only screen and (max-width: $sm) {
	.grid__item_mobile-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.grid__item_mobile-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.grid__item_mobile-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }
	.grid__item_mobile-3 {
		flex: 0 0 25%;
		max-width: 25%; } }

/* ======================================================================== */
/* 32. gridFluid */
/* ======================================================================== */
@for $i from 1 through 20 {
	.grid_fluid-#{$i} {
		margin: -#{$i}vw;
		@media only screen and (max-width: $sm) {
			margin: -1em; } }
	.grid__item_fluid-#{$i} {
		padding: #{$i}vw;
		@media only screen and (max-width: $sm) {
			padding: 1em; } }
	.grid:not(.grid_filtered) {
		.grid__item_fluid-#{$i}-fancy {
			@media only screen and (min-width: $sm + 1px) {
				&:nth-of-type(3) {
					margin-top: #{$i * 2}vw; } } } } }

@for $i from 1 through 20 {
	.grid_fluid-v-#{$i} {
		margin-top: -#{$i}vw;
		margin-bottom: -#{$i}vw;
		@media only screen and (max-width: $sm) {
			margin-top: -1em;
			margin-bottom: -1em; } }
	.grid__item_fluid-v-#{$i} {
		padding-top: #{$i}vw;
		padding-bottom: #{$i}vw;
		@media only screen and (max-width: $sm) {
			padding-top: 1em;
			padding-bottom: 1em; } }
	.grid:not(.grid_filtered) {
		.grid__item_fluid-#{$i}-fancy {
			@media only screen and (min-width: $sm + 1px) {
				&:nth-of-type(3) {
					margin-top: #{$i * 2}vw; } } } } }

@for $i from 1 through 20 {
	.grid_fluid-h-#{$i} {
		margin-left: -#{$i}vw;
		margin-right: -#{$i}vw;
		@media only screen and (max-width: $sm) {
			margin-left: -1em;
			margin-right: -1em; } }
	.grid__item_fluid-h-#{$i} {
		padding-left: #{$i}vw;
		padding-right: #{$i}vw;
		@media only screen and (max-width: $sm) {
			padding-left: 1em;
			padding-right: 1em; } }
	.grid:not(.grid_filtered) {
		.grid__item_fluid-#{$i}-fancy {
			@media only screen and (min-width: $sm + 1px) {
				&:nth-of-type(3) {
					margin-top: #{$i * 2}vw; } } } } }

/* ======================================================================== */
/* 33. listBackgrounds */
/* ======================================================================== */
.list-backgrounds_striped {
	.list-backgrounds__item:nth-of-type(odd) {
		background-color: $color-light-2; }
	.list-backgrounds__item:nth-of-type(even) {
		background-color: $color-light-1; } }
.list-backgrounds__item {
	position: relative;
	overflow: hidden; }
.list-backgrounds__wrapper-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	> * {
		height: 100%; }
	img {
		transform: scale(1.03); } }
.list-backgrounds__overlay {
	z-index: 1 !important; }
.list-backgrounds__works-list {
	@include reset-ul;
	li {
		margin-top: 0.5em;
		margin-bottom: 0.5em;
		@include trans1;
		&:first-child {
			margin-top: 0; }
		&:last-child {
			margin-bottom: 0; } } }
.list-backgrounds__overlay {
	@include trans1; }
.list-backgrounds__item_hover {
	.list-backgrounds__overlay {
		opacity: 0.6; } }

/* ======================================================================== */
/* 34. listBackgroundsThemes */
/* ======================================================================== */
[data-arts-theme-text='light'] {
  .list-backgrounds_striped {
    .list-backgrounds__item:nth-of-type(even) {
      background-color: #191919; }
    .list-backgrounds__item:nth-of-type(odd) {
      background-color: #202020; } } }

/* ======================================================================== */
/* 35. listDots */
/* ======================================================================== */
.list-dots {
  @include reset-ul;
  li {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.5em; }
  li:not(:last-child) {
    &:after {
      content: '';
      display: inline-block;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: $color-gray-2;
      vertical-align: middle;
      margin-left: 2.5em;
      margin-right: 2.5em;
      @include trans1(transform); } } }

@media screen and (max-width: $md) {
  .list-dots {
    li {
      display: block; }
    li:first-child {
      margin-top: 0; }
    li:not(:last-child) {
      &:after {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 1em;
        margin-bottom: 1em; } } }
  // .list-dots:not([data-arts-os-animation])
  //   li:not(:last-child)
  //       &:after
  //         content: ''
  //         display: inline-block
  //         width: 3px
  //         height: 3px
  //         border-radius: 50%
  //         background-color: $color-gray-2
  //         vertical-align: middle
  //         margin-left: 2.5em
  //         margin-right: 2.5em
  // .list-dots[data-arts-os-animation]
  //   li:not(:last-child)
  //     > div
  //       &:after
  //         content: ''
  //         display: inline-block
  //         width: 3px
  //         height: 3px
  //         border-radius: 50%
  //         background-color: $color-gray-2
  //         vertical-align: middle
  //         margin-left: 2.5em
  //         margin-right: 2.5em
  //         transform: scale(0)
  //         transition: transform 0.3s ease
  //         transition-delay: 0.3s
  // .list-dots[data-arts-os-animation="animated"]
  //   li:not(:last-child)
  //     > div
  //       &:after
 }  //         transform: scale(1)

/* ======================================================================== */
/* 36. logo */
/* ======================================================================== */
.logo {
	position: relative;
	display: inline-flex;
	align-items: center;
	vertical-align: middle; }
.logo__wrapper-img {
	position: relative;
	align-items: center;
	flex: 1 0 auto;
	height: 100%;
	margin-right: 15px;
	img {
		@include trans1;
		display: block; } }
.logo__text-title {
	display: block;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.3;
	color: var(--paragraph-color-dark); }
.logo__text-tagline {
	display: block;
	font-style: italic;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.3; }
.logo__img-secondary {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden; }
.logo__description {
	margin-top: 1em; }

/* ======================================================================== */
/* 37. modal */
/* ======================================================================== */
.modal-open {
	overflow: hidden;
	.modal {
		overflow-x: hidden;
		overflow-y: auto; } }

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 6000;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0; }
.modal__message {
	font-size: 18px; }
.modal-content__wrapper-button {
	text-align: center;
	.button {
		min-width: 200px; } }

.modal__close {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	z-index: 60; }

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none; }
.modal-dialog_container {
	max-width: 980px !important; }

.modal {
	&.fade .modal-dialog {
		transition: -webkit-transform 0.3s ease-out;
		transition: transform 0.3s ease-out;
		transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
		-webkit-transform: translate(0, -50px);
		transform: translate(0, -50px); }
	&.show .modal-dialog {
		-webkit-transform: none;
		transform: none; } }

@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none; } }

.modal-dialog-scrollable {
	display: -ms-flexbox;
	display: flex;
	max-height: calc(100% - 1rem);
	.modal-content {
		max-height: calc(100vh - 1rem);
		overflow: hidden; }
	.modal-header, .modal-footer {
		-ms-flex-negative: 0;
		flex-shrink: 0; }
	.modal-body {
		overflow-y: auto; } }

.modal-dialog-centered {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc(100% - 1rem);
	&::before {
		display: block;
		height: calc(100vh - 1rem);
		content: ""; }
	&.modal-dialog-scrollable {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
		height: 100%;
		.modal-content {
			max-height: none; }
		&::before {
			content: none; } } }

.modal-content {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 6px;
	outline: 0;
	padding: 3em; }

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
	&.fade {
		opacity: 0; }
	&.show {
		opacity: 0.7; } }

.modal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	.close {
		padding: 1rem 1rem;
		margin: -1rem -1rem -1rem auto; } }

.modal-title {
	margin-bottom: 0;
	line-height: 1.5; }

.modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1rem; }

.modal-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
	> {
		 :not(:first-child) {
			margin-left: .25rem; }
		 :not(:last-child) {
			margin-right: .25rem; } } }

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll; }

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 600px;
		margin: 1.75rem auto; }
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem);
		.modal-content {
			max-height: calc(100vh - 3.5rem); } }
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
		&::before {
			height: calc(100vh - 3.5rem); } }
	.modal-sm {
		max-width: 300px; } }

@media (min-width: 992px) {
	.modal-lg, .modal-xl {
		max-width: 800px; }
	.modal__message {
		font-size: 24px; } }

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1140px; } }

/* ======================================================================== */
/* 38. overlay */
/* ======================================================================== */
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	backface-visibility: hidden;
	pointer-events: none; }
.overlay_dark { // fallback
	background-color: rgba(0, 0, 0, .6); }
.overlay_light { // fallback
	background-color: rgba(255, 255, 255, .9); }
@for $i from 1 through 9 {
	.overlay_dark-#{$i * 10} {
		background-color: rgba(0, 0, 0, $i / 10); } }
@for $i from 1 through 9 {
	.overlay_light-#{$i * 10} {
		background-color: rgba(255, 255, 255, $i / 10); } }

/* ======================================================================== */
/* 39. pswp */
/* ======================================================================== */
.pswp__button {
	outline: none;
	&:focus {
		outline: none; } }
.pswp__button--arrow--left, .pswp__button--arrow--right {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	opacity: 1;
	&:before {
		display: none; } }
.pswp__button--arrow--left {
	left: 20px; }
.pswp__button--arrow--right {
	right: 20px; }
.pswp__top-bar {
	padding: 15px 15px 0; }
.pswp__counter {
	left: 25px;
	top: 15px; }
.pswp__wrapper-embed {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 120px;
	iframe {
		width: 100%;
		height: 100%; }
	video {
		width: 100%;
		height: auto; } }

@media screen and (max-width: $md) {
	.pswp__counter {
		top: 0px;
		left: 10px; }
	.pswp__top-bar {
		padding: 0; }
	.pswp__button--arrow--left, .pswp__button--arrow--right {
		width: auto;
		height: auto;
		margin-top: 0; }
	.pswp__button--arrow--left {
		left: 15px; }
	.pswp__button--arrow--right {
		right: 15px; }
	.pswp__wrapper-embed {
		padding: 120px 40px;
		iframe {
			width: 100%;
			height: 100%; } } }

/* ======================================================================== */
/* 40. sectionHorizontalScroll */
/* ======================================================================== */
.touchevents {
  [data-arts-horizontal-scroll="wrapper"] {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; } }

/* ======================================================================== */
/* 41. section */
/* ======================================================================== */
.section {
  position: relative; }
.section-dynamic-background {
  transition: background-color 0.4s ease; }
.section_z-100 {
  z-index: 100; }
.section__content {
  position: relative;
  z-index: 60; }
.section__headline {
  display: block;
  @include fluid('width', 60, 200);
  height: 1px;
  background-color: $color-dark-4; }
.section__headline:not(.section__headline_left):not(.section__headline_right) {
  + * {
    @include fluid('margin-top', 20, 40); } }
.section__headline_left, .section__headline_right {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 2em 0 0;
  z-index: 60;
  @include fluid('width', 0, 200);
  + * {
    margin-top: 0; } }
.container {
  .section__headline_left {
    left: calc(100% - ( (100vw + 1120px) / 2 ) - var(--bs-gutter-x)); }
  .section__headline_right {
    right: calc(100% - ( (100vw + 1120px) / 2 ) - var(--bs-gutter-x)); } }
.text-start {
  .section__headline {
    margin-left: 0;
    margin-right: auto; } }
.text-center {
  .section__headline {
    margin-left: auto;
    margin-right: auto; } }
.text-end {
  .section__headline {
    margin-left: auto;
    margin-right: 0; } }
.section__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0; }
.section__bg-container-right {
  max-width: 100%;
  width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px );
  margin-left: auto; }
.section__bg-container-left {
  max-width: 100%;
  width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px );
  margin-right: auto; }


@media screen and (max-width: $xxl) {
  .section__headline_left, .section__headline_right {
    @include fluid('width', 0, 100); }
  .section__bg-container-right, .section__bg-container-left {
    width: 100%; } }
@media screen and (max-width: $xxl) {
  .section__headline_left, .section__headline_right {
    display: none !important; } }

@media screen and (max-width: $md) {
  .section-fullheight__inner_mobile-auto {
    min-height: 0;
    height: 100%;
    display: block; } }

/* ======================================================================== */
/* 42. sectionFullheight */
/* ======================================================================== */
.section-fullheight {
  display: flex; }
.section-fullheight__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh; }
.section-fullheight__inner_mobile {
  height: calc(var(--fix-bar-vh, 1vh) * 100);
  min-height: 0vh; }
.section-fullheight__header {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 50%;
  z-index: 0; }
.section-fullheight__content {}


@media screen and (max-width: $md) {
  .section-fullheight__header {
    position: relative;
    top: auto;
    left: auto;
    max-width: 100%; }
  .section-fullheight__inner_mobile-auto {
    height: auto !important;
    min-height: 0 !important; } }
@media screen and (min-width: $md - 1) {
  .section-fullheight__header {
    padding-top: 0 !important; } }

/* ======================================================================== */
/* 43. sectionHeight */
/* ======================================================================== */
@for $i from 1 through 9 {
	.section_h-#{$i * 100} {
		height: #{$i * 100}px;
		max-height: 100vh; } }
.section_h-100vh {
	height: 100vh; }
.section_w-container-right {
	max-width: 100%;
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px );
	margin-left: auto;
	text-align: left; }
.section_w-container-left {
	max-width: 100%;
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px );
	margin-right: auto;
	text-align: right; }

@media screen and (max-width: $xxl) {
	.section_w-container-right, .section_w-container-left {
		width: 100%;
		text-align: center;
		.section-image__caption-vertical-left, .section-image__caption-vertical-right {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			transform: none;
			padding: 0 20px;
			margin-top: 1em;
			text-align: center;
			width: 100%; }
		.section-image__caption {
			&.text-start, &.text-center, &.text-end {
				text-align: center !important; } } } }

@media screen and (max-width: $sm) {
	@for $i from 1 through 9 {
		.section_h-#{$i * 100} {
			max-height: 70vh; } } }

/* ======================================================================== */
/* 44. sectionOffset */
/* ======================================================================== */
.section-offset__content {
	position: relative;
	width: 100%;
	z-index: 50; }

/* ======================================================================== */
/* 45. slider */
/* ======================================================================== */
.slider {
	max-width: 100%;
	width: 100%;
	height: 100%;
	.swiper-wrapper > .swiper-slide:first-of-type {
		.slider__slide-border {
			display: none; } }
	img {
		user-select: none;
		pointer-events: none; } }
.slider__wrapper-button {
	display: inline-block; }
.slider_vertical-centered {
	.swiper-wrapper {
		align-items: center !important; } }
.slider__slide-border {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: $color-border-light; }
.slider__overlay {
	z-index: 10; }
.slider__thumb-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 0;
	@include trans1; }
.slider__thumb-content {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 40px;
	z-index: 60; }
.slider__thumb-heading {
	margin-top: 0;
	margin-bottom: 0;
	color: $color-gray-3 !important;
	@include trans1; }
.slider__thumb {
	position: relative;
	background-color: #fff;
	transition: background-color 0.3s ease;
	overflow: hidden;
	&.swiper-slide-thumb-active {
		background-color: #f0f0f0;
		.slider__thumb-heading {
			color: $color-dark-1 !important; } } }
.slider__counter {
	line-height: 1; }
.slider__counter_current {
	margin-bottom: 2em;
	color: var(--color-dark-4); }
.slider__counter_total {
	color: var(--color-gray-1); }
.slider__arrow {
	width: 50px;
	height: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	transition: color 0.3s ease;
	&.swiper-button-disabled {
		user-select: none;
		opacity: .3;
		pointer-events: none !important; } }
.slider__wrapper-arrows {
	height: 24px;
	width: 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 0;
	padding-right: 0; }
.slider__wrapper-arrows_right {
	margin-right: -16px; }
.slider__wrapper-arrows_vertical {
	width: 24px;
	height: 120px;
	flex-direction: column;
	margin-left: auto; }
.slider__slide-centered {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start; }
.slider__scrollbar {
	position: relative;
	display: inline-block;
	background-color: var(--color-gray-4);
	@include trans1;
	z-index: 50; }
.slider__scrollbar_vertical {
	height: 200px;
	width: 2px;
	margin-top: auto;
	margin-bottom: auto; }
.slider__scrollbar_horizontal {
	width: 200px;
	height: 2px;
	margin-top: auto;
	margin-bottom: auto;
	.slider__scrollbar-handle {
		height: 100%;
		&:before {
			top: auto;
			transform: translateX(-50%);
			top: 0;
			left: 50%;
			right: auto;
			margin: 8px 0 0; } } }
.slider__scrollbar_horizontal_long {
	width: 360px;
	.slider__scrollbar-handle {
		&:before {
			display: none; } } }
.slider__scrollbar-handle {
	position: relative;
	background-color: var(--color-gray-3);
	color: var(--color-gray-3);
	@include trans1;
	&:before {
		content: attr(data-content);
		display: inline-block;
		@extend .small;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 100%;
		white-space: nowrap;
		margin-right: 20px; } }
.slider__wrapper-scrollbar {
	z-index: 50; }
.slider__text {
	max-width: 500px; }
.swiper-wrapper_transition {
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important; }
.slider__arrow_absolute {
	position: absolute;
	z-index: 60; }
// .slider__arrow
// 	z-index: 60
// 	outline: none
// 	&:focus, &:hover
// 		outline: none
// 	&.swiper-button-disabled
// 		pointer-events: none
// 		opacity: 0
// 		visibility: hidden
.container {
	.slider__arrow_left {
		transform: translateX(-150%) translateY(-50%);
		left: 0; }
	.slider__arrow_right {
		transform: translateX(150%) translateY(-50%);
		right: 0; } }
.slider__arrow_left {
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	left: var(--gutter-horizontal); }
.slider__arrow_right {
	top: 50%;
	transform: translateX(50%) translateY(-50%);
	right: var(--gutter-horizontal); }

@media screen and (max-width: $xxl) {
	.slider__counter_current {
		margin-bottom: 1em; } }
@media screen and (max-width: $md) {
	.slider__scrollbar_vertical {
		height: 140px; }
	.slider__scrollbar_horizontal {
		width: 140px; }
	.slider__scrollbar-handle {
		&:before {
			font-size: 11px !important;
			margin-right: 10px; } }
	.slider__wrapper-arrows_vertical {
		width: 80px;
		height: 80px; }
	.slider__wrapper-arrows {
		width: 80px; } }

/* ======================================================================== */
/* 46. sliderDots */
/* ======================================================================== */
$dot-size: 32px;

.slider__dots {
	display: inline-flex;
	// vertical-align: bottom
	.slider__dot {
		&:first-child {
			margin-left: 0; }
		&:last-child {
			margin-left: 0; } } }
.slider__dots_vertical {
	flex-direction: column;
	height: auto;
	vertical-align: initial;
	.slider__dot {
		margin: 4px 0; } }
.slider__dot {
	position: relative;
	width: $dot-size;
	height: $dot-size;
	margin: 0 6px;
	cursor: pointer;
	outline: none;
	display: inline-block;
	border-radius: 100%;
	vertical-align: middle;
	z-index: 50;
	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: auto;
		width: $dot-size;
		height: $dot-size;
		border-radius: 100%;
		stroke-width: 4px;
		z-index: 50;
		.circle {
			stroke: rgba(104, 104, 104, 1); } }
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		display: block;
		width: 4px;
		height: 4px;
		border-radius: 100%;
		background-color: rgba(104, 104, 104, .5);
		@include trans2; }
	&:hover {
		&:after {
			background-color: rgba(104, 104, 104, 1); } } }
.slider__dot_active {
	pointer-events: none;
	&:after {
		background-color: rgba(104, 104, 104, 1); } }

@media screen and (max-width: $sm) {
	.slider__dot {
		width: 20px;
		height: 20px;
		margin: 0 4px;
		svg {
			stroke-width: 8px;
			width: 20px;
			height: 20px; } }
	.slider__dots_vertical {
		.slider__dot {
			margin: 4px 0; } } }

/* ======================================================================== */
/* 47. smoothScroll */
/* ======================================================================== */
.smooth-scroll {
	overflow: hidden;
	width: 100vw;
	height: 100vh; }
[data-arts-os-animation]:not([data-arts-os-animation="animated"]) > *, [data-arts-os-animation][data-arts-os-animation-name]:not([data-arts-os-animation="animated"]) {
	opacity: 0;
	visibility: hidden;
	pointer-events: none !important;
	* {
		pointer-events: none !important; } }
// .section-masthead[data-arts-os-animation]:not([data-arts-os-animation="animated"]) > *
// 	opacity: 0
// 	visibility: hidden

body.elementor-editor-active [data-arts-os-animation] {
	opacity: 1;
	visibility: visible; }
[data-arts-scroll-fixed] {
	width: 100% !important;
	left: 0 !important;
	top: 0 !important; }

/* ======================================================================== */
/* 48. social */
/* ======================================================================== */
.social {
	display: inline-block;
	@include reset-ul;
	.social__item {
		display: inline-block;
		transition: none;
		margin-bottom: 0;
		a {
			display: flex !important;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			font-size: 14px;
			border-radius: 50%;
 } } }			// border: 1px solid $color-border-dark

@media screen and (max-width: $md) {
	.social {
		.social__item {
			a {
				width: 30px;
				height: 30px; } } } }

/* ======================================================================== */
/* 49. swatch */
/* ======================================================================== */
.swatch {}
.swatch__figure {
  display: inline-block;
  border-radius: 50%; }
.swatch__figure_color {
  width: 120px;
  height: 120px; }
.swatch__header {
  display: block;
  width: 100%; }
.swatch__subheading {
  color: var(--color-gray-3); }

/* ======================================================================== */
/* 50. table */
/* ======================================================================== */
table {
	border-collapse: collapse;
	width: 100%;
	// margin: 0 0 24px
 }	// width: calc(100% - 30px)

thead th {
	border-bottom: 1px solid $color-gray-1;
	padding-bottom: 0.5em; }

th {
	padding: 1em;
	text-align: left; }

tr {
	border-bottom: 1px solid $color-border-dark; }

td {
	padding: 1em; }

th:first-child, td:first-child {
	padding-left: 0; }

th:last-child, td:last-child {
	padding-right: 0; }

.table_no-outer-borders {
	table-layout: fixed;
	border-collapse: collapse;
	border-style: hidden;
	tr {
		border-collapse: collapse;
		border-style: hidden;
		border: 1px solid $color-border-dark; }
	td {
		padding: 2em;
		border-collapse: collapse;
		border: 1px solid $color-border-dark; } }

@media screen and (max-width: $xs) {
	.table-xs-flex {
		display: block;
		tbody, tr, td {
			display: block; }
		&.table_no-outer-borders {
			tr {
				border: none; }
			td {
				padding: 2em;
				border-left: none;
				border-right: none;
				border-top: none;
				// border-
 }				// border: none
			tr:first-child {
				td:first-child {
						margin-top: -2em; } }
			tr:last-child {
				td:last-child {
					border-bottom: none;
					margin-bottom: -2em; } } } } }

/* ======================================================================== */
/* 51. themes */
/* ======================================================================== */
[data-arts-theme-text="light"] {
	color: $color-gray-2;
	.xxl {
		color: var(--xxl-color-light); }
	.xl {
		color: var(--xl-color-light); }
	h1, .h1 {
		color: var(--h1-color-light); }
	h2, .h2 {
		color: var(--h2-color-light); }
	h3, .h3 {
		color: var(--h3-color-light); }
	h4, .h4 {
		color: var(--h4-color-light); }
	h5, .h5 {
		color: var(--h5-color-light); }
	h6, .h6 {
		color: var(--h6-color-light); }
	p, .paragraph {
		color: var(--paragraph-color-light); }
	.subheading {
		color: var(--subheading-color-light); }
	blockquote, .blockquote {
		color: var(--blockquote-color-light);
		p {
			color: var(--blockquote-color-light); }
		cite {
			color: var(--blockquote-color-light); } }
	a, .button {
		color: var(--color-gray-1);
		&:hover {
			color: #fff; } }
	.button.button_bordered {
		&:hover {
			border-color: #fff; } }
	.header__label {
		color: #fff; }
	.header__overlay-menu-back {
		&:hover {
			color: var(--color-gray-1); } }
	.breadcrumbs, .categories {
		li:not(:last-child):after {
			color: #fff; } }
	.slider__scrollbar {
		background-color: var(--color-gray-3); }
	.slider__scrollbar-handle {
		background-color: #fff;
		color: #fff; }
	.slider__counter_current {
		color: #fff; }
	.slider__arrow {
		&:hover {
			color: #fff; } }
	.slider__dot svg .circle {
		stroke: #fff; }
	.slider__dot {
		border-color: $color-border-light;
		&:after {
			background-color: $color-border-light; }
		&:hover {
			&:after {
				background-color: #fff; } } }
	.slider__dot_active {
		&:after {
			background-color: #fff; } }
	.section__headline {
		background-color: #dadada; }
	.post-meta {
		li:not(:last-child):after {
			color: #dadada; } } }

/* ======================================================================== */
/* 52. transitionCurtain */
/* ======================================================================== */
.transition-curtain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: bottom center;
  margin: auto;
  z-index: 400;
  pointer-events: none; }

/* ======================================================================== */
/* 53. typography */
/* ======================================================================== */
h1, h2, h3 {
	margin-top: 0.5em;
	margin-bottom: 0.5em; }
h4, h5, h6 {
	margin-top: 0.75em;
	margin-bottom: 0.75em; }
.xxl {
	font-family: var(--font-primary);
	font-weight: 100;
	line-height: var(--xxl-line-height);
	@include fluid-type(var(--xxl-min-font-size), var(--xxl-max-font-size));
	color: var(--xxl-color-dark);
	letter-spacing: calc(var(--xxl-letter-spacing) * 1px); }
.xl {
	font-family: var(--font-primary);
	font-weight: 100;
	line-height: var(--xl-line-height);
	@include fluid-type(var(--xl-min-font-size), var(--xl-max-font-size));
	color: var(--xl-color-dark);
	letter-spacing: calc(var(--xl-letter-spacing) * 1px); }
h1, .h1 {
	font-family: var(--font-primary);
	font-weight: 100;
	line-height: var(--h1-line-height);
	@include fluid-type(var(--h1-min-font-size), var(--h1-max-font-size));
	color: var(--h1-color-dark);
	letter-spacing: calc(var(--h1-letter-spacing) * 1px); }
h2, .h2 {
	font-family: var(--font-primary);
	font-weight: 200;
	line-height: var(--h2-line-height);
	@include fluid-type(var(--h2-min-font-size), var(--h2-max-font-size));
	color: var(--h2-color-dark);
	letter-spacing: calc(var(--h2-letter-spacing) * 1px); }
h3, .h3 {
	font-family: var(--font-primary);
	font-weight: 300;
	line-height: var(--h3-line-height);
	@include fluid-type(var(--h3-min-font-size), var(--h3-max-font-size));
	color: var(--h3-color-dark);
	letter-spacing: calc(var(--h3-letter-spacing) * 1px); }
h4, .h4 {
	font-family: var(--font-primary);
	font-weight: 500;
	line-height: var(--h4-line-height);
	@include fluid-type(var(--h4-min-font-size), var(--h4-max-font-size));
	color: var(--h4-color-dark);
	letter-spacing: calc(var(--h4-letter-spacing) * 1px); }
h5, .h5 {
	font-family: var(--font-primary);
	font-weight: 500;
	line-height: var(--h5-line-height);
	@include fluid-type(var(--h5-min-font-size), var(--h5-max-font-size));
	color: var(--h5-color-dark);
	letter-spacing: calc(var(--h5-letter-spacing) * 1px); }
h6, .h6 {
	font-family: var(--font-primary);
	font-weight: 500;
	line-height: var(--h6-line-height);
	@include fluid-type(var(--h6-min-font-size), var(--h6-max-font-size));
	color: var(--h6-color-dark);
	letter-spacing: calc(var(--h6-letter-spacing) * 1px); }
p {
	margin-top: 1em;
	margin-bottom: 1em;
	font-weight: 400;
	@include fluid-type(var(--paragraph-min-font-size), var(--paragraph-max-font-size));
	line-height: var(--paragraph-line-height);
	letter-spacing: calc(var(--paragraph-letter-spacing) * 1px);
	color: var(--paragraph-color-dark); }
.paragraph {
	font-weight: 400;
	@include fluid-type(var(--paragraph-min-font-size), var(--paragraph-max-font-size));
	line-height: var(--paragraph-line-height);
	letter-spacing: calc(var(--paragraph-letter-spacing) * 1px);
	color: var(--paragraph-color-dark); }
blockquote, .blockquote {
	display: block;
	@include fluid-type(var(--blockquote-min-font-size), var(--blockquote-max-font-size));
	line-height: 1.6;
	letter-spacing: calc(var(--blockquote-letter-spacing) * 1px);
	font-style: italic;
	margin-top: 2em;
	margin-bottom: 2em;
	color: var(--blockquote-color-dark);
	p {
		margin-top: 0;
		@include fluid-type(var(--blockquote-min-font-size), var(--blockquote-max-font-size));
		line-height: 1.6;
		color: var(--blockquote-color-dark);
		&:last-child, &:last-of-type {
			margin-bottom: 0; } }
	cite {
		display: block;
		margin-top: 1em;
		@include fluid-type(var(--paragraph-min-font-size), var(--paragraph-max-font-size));
		line-height: var(--paragraph-line-height);
		color: var(--paragraph-color-dark);
		font-style: normal;
		font-weight: 700; } }
blockquote {
	border-left: 4px solid $color-dark-1;
	padding-left: 1.5em; }
small, .small {
	font-weight: 500;
	font-size: 13px; }
.subheading {
	font-weight: 700;
	line-height: var(--subheading-light-height);
	@include fluid-type(var(--subheading-min-font-size), var(--subheading-max-font-size));
	color: var(--subheading-color-dark);
	text-transform: uppercase;
	letter-spacing: calc(var(--subheading-letter-spacing) * 1px); }
.has-drop-cap:not(:focus):not(.has-drop-cap_split) {
	&:first-letter {
		@include dropcap;
		&:after {
			content: '';
			display: table;
			clear: both; } } }
.arts-split-text:not(.js-arts-split-text) {
	.has-drop-cap {
		> div:first-child {
			display: inline-block !important;
			float: left;
			&:after {
				content: '';
				display: table;
				clear: both; } }
		&:first-letter {
			margin: unset;
			float: unset;
			font-size: unset;
			text-transform: unset; } } }
.arts-split-text__line {
	.overflow.d-inline-block {
		vertical-align: top; } }
.split-li-wrapper {
	display: inline-block;
	vertical-align: middle; }
.has-split-list-items {
	li {
		&:before {
			transform-origin: left center;
			transform: scaleX(0); }
		&:after {
			transform-origin: center center;
			transform: scale(0); } } }
.has-split-list-items_animated {
	li {
		&:before, &:after {
			@include trans2(transform);
			transition-delay: 0.3s;
			transform: scale(1); } } }
.strong {
	font-weight: bold; }
.em {
	font-style: italic; }

@media screen and (max-width: $xs) {
	blockquote {
		padding-left: 1em; } }

/* ======================================================================== */
/* 54. utilities */
/* ======================================================================== */
.overflow {
	position: relative;
	overflow: hidden;
	&.d-inline-block {
		vertical-align: middle; } }
.overflow-initial {
	overflow: initial; }
.position-relative {
	position: relative; }
.position-fixed {
	position: fixed; }
.of-cover {
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
	font-family: 'object-fit: cover;'; }
.of-contain {
	width: 100%;
	height: 100%;
	object-fit: contain;
	font-family: 'object-fit: contain;'; }
.hidden {
	opacity: 0;
	visibility: hidden; }
.no-gutters {
	padding-left: 0;
	padding-right: 0; }
.grayscale {
	filter: grayscale(70%); }
.w-100 {
	width: 100% !important; }
.w-100vh {
	width: 100vh !important; }
.w-100vw {
	width: 100vw !important; }
.h-100 {
	height: 100% !important; }
.h-100vh {
	height: 100vh !important; }
.h-100vw {
	height: 100vw !important; }
.clearfix:after {
	content: '';
	display: table;
	clear: both; }
.backgroundblendmode {
	.blend-difference {
		mix-blend-mode: difference;
		color: #fff;
		a {
			color: #fff;
			&:hover {
				opacity: .7; } } } }
.hidden_absolute {
	position: absolute;
	top: -9999px;
	left: -9999px;
	opacity: 0;
	visibility: hidden; }
#js-webgl {
	display: none; }
.d-flex-centered {
	display: flex;
	align-items: center;
	justify-content: center; }
.z-50 {
	z-index: 50; }
.z-100 {
	z-index: 100; }
.z-500 {
	z-index: 500; }
.z-1000 {
	z-index: 1000; }

.block-circle {
	border-radius: 100%; }
.block-counter {
	padding-left: 0.75em;
	padding-right: 0.75em;
	position: relative; }
.block-counter__counter {
	position: absolute;
	top: -6px;
	left: calc(100% - 6px);
	@extend .subheading;
	letter-spacing: 0;
	white-space: nowrap; }
.border-right {
	border-right: 1px solid $color-border-dark; }

@media screen and (min-width: $md + 1px) {
	.h-lg-100 {
		height: 100% !important; }
	.h-lg-100vh {
		height: 100vh !important; }
	.h-lg-100vh-fix-bar {
		height: calc(var(--fix-bar-vh, 1vh) * 100) !important; }
	.h-lg-100vw {
		width: 100vw !important; }
	.row-wide {
		margin-left: -50px;
		margin-right: -50px; }
	.col-wide {
		padding-left: 50px;
		padding-right: 50px; } }

.pointer-events-none {
	pointer-events: none !important;
	*:not(.pointer-events-auto) {
		pointer-events: none !important; } }
// [data-arts-os-animation].pointer-events-none
// 	pointer-events: none !important
// 	.pointer-events-auto, .pointer-events-none .pointer-events-auto
// 		pointer-events: none !important
// 		*
// 			pointer-events: none !important
// 	*
// 		pointer-events: none !important
// 	.slider-projects__slide.swiper-slide-active *
// 		pointer-events: none !important
.pointer-events-auto, .pointer-events-none .pointer-events-auto {
	pointer-events: auto !important;
	* {
		pointer-events: auto !important; } }
.swiper-slide:not(.swiper-slide-active) {
	.pointer-events-auto, .pointer-events-none .pointer-events-auto {
		pointer-events: none !important;
		* {
			pointer-events: none !important; } } }
.border-radius-100 {
	border-radius: 100%; }
.grayscale {
	filter: grayscale(70%); }
.border-none {
	border: none !important; }

/* ======================================================================== */
/* 55. widget */
/* ======================================================================== */
.widget {
	margin-bottom: 2em;
	font-size: 16px;
	p {
		font-size: 16px; }
	select {
		width: 100%;
		max-width: 100%; }
	ul {
		@include reset-ul;
		text-align: left;
		li {
			display: block;
			margin-bottom: 0.5em;
			&:last-child {
				margin-bottom: 0; } }
		ul {
			width: 100%;
			padding-left: 1em;
			margin-top: 1em; }
		.sub-menu {
			padding-left: 1em;
			margin-top: 1em; } } }
.widget:not(.widget_kinsey_cta) {
	p {
		&:last-of-type {
			margin-bottom: 0; } } }
.widgettitle {
	display: block;
	font-size: 16px;
	font-weight: 500;
	color: $color-dark-1;
	line-height: var(--paragraph-line-height);
	margin-top: 0;
	margin-bottom: 1em;
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		background-color: $color-dark-1;
		margin-bottom: 0.5em; } }
.widget-area_no-margin-last-widget {
	> * {
		&:last-child {
			margin-bottom: 0; } } }

/* ======================================================================== */
/* 56. widgetArchive */
/* ======================================================================== */
.widget_archive {
	ul {
		li {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			span {
				font-size: 14px;
				line-height: 1; } } } }

/* ======================================================================== */
/* 57. widgetCalendar */
/* ======================================================================== */
#wp-calendar {
	width: 100%;
	text-align: center;
	thead {
		border-bottom: 1px solid $color-border-dark; }
	caption {
		caption-side: top;
		width: 100%;
		text-align: center;
		padding-top: 0;
		padding-bottom: 10px; }
	th {
		font-weight: 600;
		padding: 5px;
		text-align: center; }
	td {
		padding: 5px;
		&#next {
			text-align: right; }
		&#prev {
			text-align: left; } }
	tbody {
		a {
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 30px;
				height: 30px;
				border-radius: 100%;
				transform: translate(-50%, -50%);
				z-index: -1;
				@include trans1; } } }

	tr {
		border-bottom: none; }
	a {
		display: inline-block;
		position: relative;
		z-index: 50; } }
.wp-calendar-nav {
	display: flex;
	justify-content: space-between;
	align-content: center;
	width: 100%; }

/* ======================================================================== */
/* 58. widgetCategories */
/* ======================================================================== */
.widget_categories {
	ul {
		li {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			span {
				font-size: 14px;
				line-height: 1; } } } }

/* ======================================================================== */
/* 59. widgetMenuInline */
/* ======================================================================== */
.widget_kinsey_menu_inline {
	ul {
		li {
			display: inline-block;
			margin-bottom: 0;
			a {
				padding: 4px;
				&:before {
					display: none; } } } } }

@media screen and (max-width: $md) {
	.widget_kinsey_menu_inline {
		.menu {
			text-align: center; } } }

/* ======================================================================== */
/* 60. widgetPolylang */
/* ======================================================================== */
.widget_polylang {
	display: inline-block;
	font-size: 14px;
	select {
		width: auto;
		display: inline-block;
		padding: 5px 25px 5px 15px; }
	ul {
		@include reset-ul;
		li {
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 0; } } }

/* ======================================================================== */
/* 61. widgetRSS */
/* ======================================================================== */
.widget_rss {
	.rsswidget {
		font-weight: 500; }
	.rss-date {
		display: block;
		width: 100%;
		font-size: 13px;
		margin-top: 0.5em;
		margin-bottom: 1em; }
	.rssSummary {
		margin-top: 1em;
		margin-bottom: 1em; }
	ul {
		> li {
			border-bottom: 1px solid $color-border-dark;
			padding-bottom: 1em;
			&:last-child {
				border-bottom: none;
				padding-bottom: 0; } } } }

/* ======================================================================== */
/* 62. widgetRecentComments */
/* ======================================================================== */
.widget_recent_comments {
	ul {
		li {
			padding: 20px 0;
			margin-bottom: 0 !important;
			border-bottom: 1px solid $color-border-dark;
			&:first-child {
				padding-top: 0; }
			&:last-child {
				padding-bottom: 0;
				border-bottom: none; }
			a {
				// font-family: $font-secondary
				font-weight: bold; } } }
	.comment-author-link {
		a {
			// font-family: $font-primary
			font-weight: normal; } } }

/* ======================================================================== */
/* 63. widgetRecentEntries */
/* ======================================================================== */
.widget_recent_entries {
	.post-date {
		display: block;
		width: 100%;
		font-size: 13px;
		font-weight: 500;
		margin-top: 0.25em;
		color: $color-gray-3; }
	ul {
		li {
			padding: 20px 0;
			margin-bottom: 0 !important;
			border-bottom: 1px solid $color-border-dark;
			a {
				font-weight: 500;
				line-height: 1.5;
				span {
					display: inline-block; } }
			&:first-child {
				padding-top: 0; }
			&:last-child {
				padding-bottom: 0;
				border-bottom: none; } } } }

/* ======================================================================== */
/* 64. widgetSocial */
/* ======================================================================== */
.widget_kinsey_social {
	ul {
		text-align: inherit;
		li {
			display: inline-block;
			margin-bottom: 0; } } }

/* ======================================================================== */
/* 65. widgetTagCloud */
/* ======================================================================== */
.widget_tag_cloud {
 }	//

/* ======================================================================== */
/* 66. widgetWPML */
/* ======================================================================== */
.widget_icl_lang_sel_widget {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
	font-size: 14px;
	.wpml-ls-legacy-dropdown {
		width: auto;
		border: 1px solid $color-border-dark;
		border-radius: 2px;
		padding: 2px 5px;
		a {
			border: none;
			background: transparent;
			&:hover, &:focus {
				opacity: 1; } } }
	.wpml-ls-legacy-dropdown a:hover, .wpml-ls-legacy-dropdown a:focus, .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a {
		background: transparent; }
	.wpml-ls-sub-menu {
		border-top: none; }
	.wpml-ls-legacy-list-horizontal {
		padding: 2px 5px;
		.wpml-ls-item {
			display: inline-block;
			margin-bottom: 0; } } }
.lang-switch-no-padding-right {
	.widget_icl_lang_sel_widget {
		.wpml-ls-legacy-list-horizontal {
			padding-right: 0; } } }

/* ======================================================================== */
/* 67. author */
/* ======================================================================== */
.author {
	display: flex;
	width: 100%; }
.author__name {
	font-size: 13px;
	line-height: 1.2;
	font-weight: 500;
	color: $color-gray-3; }
.author__avatar {
	overflow: hidden;
	border-radius: 50%;
	margin-right: 1em; }

/* ======================================================================== */
/* 68. comments */
/* ======================================================================== */
// .comments-area, .comment-respond
// .comments-title
// 	font-size: 24px
// 	font-weight: 600
// 	line-height: 1.5
// 	margin-top: 0
// 	margin-bottom: 10px
.comments-title, .comment-reply-title {
	@extend .h3;
	margin-top: 0;
	margin-bottom: 0; }
.comment-list {
	@include reset-ul;
	// margin-top: 1em
	padding-left: 0 !important;
	> li {
		padding-top: 1.5em;
		padding-bottom: 1.5em;
		ol.children {
			@include reset-ul;
			padding-left: 4%;
			li {
				padding-top: 1.5em;
				padding-bottom: 1.5em; }
			li:last-child {
				padding-bottom: 0; } } }
	> li:not(:last-child) {
		border-bottom: 1px solid $color-border-dark; }
	> ol {
		@include reset-ul; } }
.comment-author {
	max-width: 100px;
	margin-right: 2em;
	flex: 0 0 auto;
	.avatar {
		position: relative;
		width: 100%;
		max-width: 80px;
		max-height: 80px;
		border-radius: 100%;
		display: block; } }
.comment-meta {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.5em; }
.comment-metadata {
	@extend .small;
	margin-left: 10px;
	a {
		border-bottom: none !important; } }
.comment-body {
	display: flex;
	.fn {
		font-family: var(--font-primary);
		font-weight: 700;
		line-height: var(--h5-line-height);
		@include fluid-type(var(--h5-min-font-size), var(--h5-max-font-size));
		color: var(--h5-color-dark); } }
.comment-content {
	width: 100%; }
.reply {
	display: inline-block;
	line-height: 1;
	margin-top: 0.5em;
	margin-bottom: 0.5em; }
.comment-reply-link {
	@extend .small;
	border-bottom: none !important;
	margin-right: 24px;
	&:before {
		content: '\e15e';
		font-family: 'Material Icons';
		text-transform: none;
		margin-right: 8px; } }
.comment-respond {
	margin-top: 1em; }
.comment-edit-link {
	@extend .small;
	color: $color-gray-3;
	border-bottom: none !important;
	&:before {
		content: '\e3c9';
		font-family: 'Material Icons';
		text-transform: none;
		margin-right: 6px; } }
.trackback, .pingback {
	.edit-link {
		margin-left: 0.5em; } }

@media only screen and (max-width: $md) {
	.comment-author {
		margin-right: 1em;
		.avatar {
			max-width: 50px;
			max-height: 50px; } }
	.comment-meta {
		flex-wrap: wrap; }
	.comment-metadata {
		margin-top: 0;
		margin-left: 0;
		width: 100%; } }

/* ======================================================================== */
/* 69. figurePost */
/* ======================================================================== */
.figure-post {
	&.hover-zoom-underline {
		.figure-post__media-link {
			transform: scale(1.05); } } }
.figure-post_card {
	overflow: hidden;
	border: 1px solid $color-border-dark;
	border-radius: 4px;
	&:hover {
		border-color: transparent; } }
.figure-post__media {
	overflow: hidden;
	position: relative; }
.figure-post__date {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	will-change: transform;
	position: absolute;
	top: 0;
	left: 0;
	@include fluid('width', 70, 100);
	@include fluid('height', 70, 100);
	z-index: 50;
	text-align: center; }
.text-center {
	.figure-post__date {
		right: 0;
		margin: 0 auto; } }
.figure-post__date_centered {
	right: 0;
	margin: 0 auto; }
.figure-post__date-day {
	display: block;
	font-weight: normal;
	line-height: 1; }
.figure-post__date-month {
	display: block;
	line-height: 1; }
.figure-post__media-link {
	display: block;
	@include trans1;
	transform-origin: center center; }

.figure-post__date_small {
	@include fluid('width', 60, 80);
	@include fluid('height', 60, 80); }
.figure-post__header {
	a.underline-hover__target {
		transition-property: background-size, color; } }

@media screen and (max-width: $xs) {
	.figure-post_card {
		padding: 20px !important; } }

/* ======================================================================== */
/* 70. gallery */
/* ======================================================================== */
.gallery {
	margin-top: 2em;
	margin-bottom: 2em; }

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%; }

.gallery-columns-2 .gallery-item {
	max-width: 50%; }

.gallery-columns-3 .gallery-item {
	max-width: 33.33%; }

.gallery-columns-4 .gallery-item {
	max-width: 25%; }

.gallery-columns-5 .gallery-item {
	max-width: 20%; }

.gallery-columns-6 .gallery-item {
	max-width: 16.66%; }

.gallery-columns-7 .gallery-item {
	max-width: 14.28%; }

.gallery-columns-8 .gallery-item {
	max-width: 12.5%; }

.gallery-columns-9 .gallery-item {
	max-width: 11.11%; }

.gallery-caption {
	display: block; }

/* ======================================================================== */
/* 71. post */
/* ======================================================================== */
.post {}
.post__tags {
	border-top: 1px solid $color-border-dark;
	margin-top: 2em;
	padding-top: 2em; }
.post__content, .post__comments, .section-content__heading, .section-content__text, .content {
	> *:first-child:not(.section__headline) {
		margin-top: 0; }
	> *:last-child:not(.section__headline) {
		margin-bottom: 0; }
	ul {
		@include reset-ul;
		margin-bottom: 1.5em;
		li {
			display: block;
			margin-top: 1em;
			margin-bottom: 1em; }
		ul, ol {
			padding-left: 1em; } }
	ul:not(.wp-block-gallery) {
		padding-left: 1.5em;
		li {
			&:before {
				content: '';
				display: inline-block;
				width: 1em;
				height: 2px;
				vertical-align: middle;
				margin-right: 0.5em;
				// margin-bottom: 3px
				background-color: var(--color-gray-1); }
			> span { // split text wrapper
				vertical-align: top !important; } } }
	ol:not(.comment-list) {
		margin-bottom: 24px;
		padding-left: 1.25em;
		li {
			display: list-item;
			margin-top: 0.75em;
			margin-bottom: 0.75em;
			> span { // split text wrapper
				vertical-align: top !important; } }
		ul, ol {
			padding-left: 1.25em; } } }
.post__content, .comment-content, .section-content__heading, .section-content__text {
	> ul {
		padding-left: 0 !important; } }

/* ======================================================================== */
/* 72. pagination */
/* ======================================================================== */
.pagination {
	border-top: 2px solid $color-border-dark;
	padding: 10px 0 0;
	font-weight: 500;
	.nav-links {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	.nav-links__container {
		margin-left: auto;
		margin-right: auto; }
	.page-numbers {
		display: inline-block;
		line-height: 1;
		padding: 12px 20px;
		background-image: none;
		vertical-align: middle;
		transition-property: color;
		&.prev {
			font-size: 24px;
			font-weight: normal;
			margin-left: -20px; }
		&.next {
			font-size: 24px;
			font-weight: normal;
			margin-right: -20px; }
		&:not(a) {
			color: $color-dark-4; }
		&.current {
			color: $color-dark-4; } } }
.page-links {
	border-top: 2px solid $color-border-dark;
	padding: 10px 0 0;
	.page-number {
		display: inline-block;
		line-height: 1;
		font-weight: 600;
		padding: 0 15px;
		border: none; }
	.post-page-numbers {
		&:not(a) {
			color: $color-dark-4; } } }

.comments-pagination {
	text-align: center;
	.page-numbers {
		display: inline-block;
		line-height: 1;
		font-weight: 600;
		padding: 0 10px;
		transition-property: color; } }

@media screen and (max-width: $md) {
	.pagination {
		padding: 8px 0 0; }
	.page-links {
		padding: 8px 0 0; } }

/* ======================================================================== */
/* 73. postMeta */
/* ======================================================================== */
.post-meta {
	@include reset-ul;
	font-size: 13px;
	line-height: 1;
	font-weight: 500;
	i {
		font-size: 14px !important;
		vertical-align: middle;
		margin-right: 4px;
 }		// margin-bottom: 2px
	li {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		line-height: 1.6;
		vertical-align: middle;
		span {
			vertical-align: middle; } }
	li:not(:last-child):after {
		content: '/';
		color: $color-dark-1;
		display: inline-block;
		margin-left: 6px;
		margin-right: 5px;
		@include trans1;
 }		// transition: opacity 0.6s ease
	ul {
		padding-left: 0; }
	a {
		display: inline-block;
		vertical-align: middle;
		white-space: nowrap; } }
.post-meta_block {
	li {
		display: block;
		margin: 0.5em 0; }
	li:not(:last-child):after {
		display: none; } }
.post-meta_flex {
	display: flex;
	justify-content: space-between;
	li:not(:last-child):after {
		display: none; } }
.post-meta__divider {
	display: inline-block;
	vertical-align: middle;
	margin: 0 1em;
	@include fluid('width', 60, 100);
	height: 1px;
	background-color: $color-border-dark; }
.post-meta_mini {
	li:after {
		display: none !important; } }
.post-meta_mini-small {
	li:after {
		display: none !important; }
	.post-meta__divider {
		width: 50px; } }

/* ======================================================================== */
/* 74. sidebar */
/* ======================================================================== */
.sidebar {}

/* ======================================================================== */
/* 75. tags */
/* ======================================================================== */
.tagcloud, .widget .tagcloud {
	a {
		display: inline-block;
		font-size: 13px;
		font-weight: 500;
		line-height: 1;
		background-color: transparent;
		padding: 8px 14px;
		margin-bottom: 6px;
		margin-right: 4px;
		border-radius: 100px;
		background-color: $color-light-3;
		&:hover {
			border-color: $color-dark-1; } }
	ul {
		@include reset-ul;
		li {
			display: inline-block;
			margin-bottom: 0; } } }

/* ======================================================================== */
/* 76. select */
/* ======================================================================== */
select {
	display: block;
	border: 1px solid $color-border-dark;
	border-radius: 2px;
	padding: 8px 35px 8px 15px;
	background-color: transparent;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url('../img/general/select_black.png');
	background-position: right 15px bottom 50%;
	background-repeat: no-repeat;
	background-size: 6px 4px; }

/* ======================================================================== */
/* 77. inputSearch */
/* ======================================================================== */
.input-search__input {
	padding-right: 30px; }
.input-search__submit {
	display: inline-block;
	font-size: 18px;
	width: 24px;
	height: 24px;
	padding: 0;
	position: absolute;
	right: 0;
	top: 12px;
	bottom: 0;
	margin: auto;
	background: transparent;
	border: none;
	color: $color-gray-3;
	outline: none;
	@include trans1;
	cursor: pointer;
	&:hover {
		color: $color-dark-1; }
	&:focus {
		outline: none; } }

/* ======================================================================== */
/* 78. formContacts */
/* ======================================================================== */
@media screen and(max-width: $md) {
	.form-contact {
		padding-left: var(--bs-gutter-x);
		padding-right: var(--bs-gutter-x); } }

/* ======================================================================== */
/* 79. inputFloat */
/* ======================================================================== */
.input-float {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 0; }
.input-float__label {
	position: absolute;
	top: 0.75em;
	left: 0;
	margin: auto;
	display: block;
	font-size: 16px;
	@include trans1;
	transform-origin: left center;
	cursor: text;
	color: $color-gray-3; }
.input-float__input {
	display: block;
	width: 100%;
	width: 100%;
	border-bottom: 1px solid $color-border-dark;
	border-top: none;
	border-right: none;
	border-left: none;
	outline: none;
	padding: 10px 0 4px;
	@include trans1;
	background-color: transparent;
	border-radius: 0;
	color: inherit; }
.input-float__input_textarea {
	resize: none;
	height: 200px; }

.input-float__input_focused, .input-float__input_not-empty {
	+ .input-float__label {
		transform: scale(0.8571) translateY(-1.75rem); } }

.input-float__input_focused {
	border-color: $color-dark-1;
	+ .input-float__label {
			color: $color-dark-1; } }

/* ======================================================================== */
/* 80. lazyLoad */
/* ======================================================================== */
.lazy-wrapper {
  display: inline-block;
  width: 100%;
  vertical-align: middle; }

/* ======================================================================== */
/* 81. footer */
/* ======================================================================== */
.footer {
	color: $color-gray-3;
	z-index: 100;
	.widgettitle {
		display: block;
		font-size: 14px;
		font-weight: 600;
		line-height: 1;
		margin-top: 0;
		margin-bottom: 1em;
		&:after {
			display: none; } } }
.footer__divider {
	display: block;
	position: absolute;
	top: 0;
	left: calc(var(--bs-gutter-x) / 2);
	right: calc(var(--bs-gutter-x) / 2);
	height: 1px;
	padding-left: 0;
	padding-right: 0;
	width: auto;
	background-color: $color-border-dark; }


/* ======================================================================== */
/* 82. footerLogo */
/* ======================================================================== */
[data-arts-footer-logo="primary"] {
	.logo__img-primary {
		opacity: 1;
		visibility: visible; }
	.logo__img-secondary {
		opacity: 0;
		visibility: hidden; } }
[data-arts-footer-logo="secondary"] {
	.logo__img-primary {
		opacity: 0;
		visibility: hidden; }
	.logo__img-secondary {
		opacity: 1;
		visibility: visible; } }

/* ======================================================================== */
/* 83. preloader */
/* ======================================================================== */
.preloader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 400;
	overflow: hidden; }
.preloader_header-hidden {
	+ #page-header {
		transition: opacity 0.3s ease; }
	&:not(.preloader_ended) {
		+ #page-header {
			opacity: 0;
			visibility: hidden;
			transform: translateY(50px); } } }
.preloader_header-menu-hidden {
	+ #page-header {
		.menu, .header__burger {
			transition: opacity 0.3s ease; } }
	&:not(.preloader_ended) {
		+ #page-header {
			.menu, .header__burger {
				opacity: 0;
				visibility: hidden; } } } }
.preloader:not(.preloader_ended) + #page-header {
	pointer-events: none !important;
	* {
		pointer-events: none !important; } }
.preloader__wrapper {
	display: flex;
	flex-wrap: wrap;
	position: fixed;
	top: var(--gutter-vertical, 120px);
	left: var(--gutter-horizontal, 120px);
	right: var(--gutter-horizontal, 120px);
	bottom: calc(var(--fix-bar-vh, 30px) + var(--gutter-vertical, 100px)); }
.preloader__counter {
	font-family: var(--font-primary);
	font-weight: 100;
	line-height: 1;
	@include fluid-type(96, 212);
	letter-spacing: -10px;
	// opacity: 0
	white-space: nowrap; }
.underline {
	&.preloader__counter_started {
		animation-name: loading;
		animation-duration: 20s;
		transition: background-size 1.2s ease; }
	&.preloader__counter_paused {
		animation-play-state: paused; }
	&.preloader__counter_ended {
		animation-duration: 1s; } }

@keyframes loading {
	0% {
		background-size: 0% 2px; }
	100% {
		background-size: 100% 2px; } }

/* ======================================================================== */
/* 84. header */
/* ======================================================================== */
.header {
	pointer-events: none;
	padding-top: calc(var(--gutter-vertical) / 1.5);
	a, select, input {
		pointer-events: initial; }
	&:not(.opened) {
		.header__label-burger_closed-hover, .header__label-burger_opened-open, .header__label-burger_opened-hover {
			transform: translateY(100%);
			opacity: 0; }
		.header__burger {
			&:hover {
				.header__label-burger_closed-open {
					transform: translateY(-100%);
					opacity: 0; }
				.header__label-burger_closed-hover {
					transform: translateY(0%);
					opacity: 1; } } } }
	&.opened {
		.header__label-burger_closed-open, .header__label-burger_closed-hover, .header__label-burger_opened-hover {
			transform: translateY(100%);
			opacity: 0; }
		.header__burger {
			&:hover {
				.header__label-burger_opened-open {
					transform: translateY(-100%);
					opacity: 0; }
				.header__label-burger_opened-hover {
					transform: translateY(0%);
					opacity: 1; } } } } }
.cursorfollower {
	.header__overlay-menu-back {
		&[data-arts-cursor] {
			.header__label {
				pointer-events: none !important;
				transition: all 0.3s ease !important; }
			&:hover {
				.header__label_status {
					transform: translate(25px, -100%);
					opacity: 0; }
				.header__label_opened-back {
					transform: translate(25px, -50%);
					opacity: 1; } } } }
	.header__burger {
		&[data-arts-cursor] {
			.header__burger-label {
				pointer-events: none !important; }
			&:hover {
				.header__burger-label {
					transform: translate(-30px, -50%); } } } } }
.header__controls {
	pointer-events: none; }
.header_absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 500; }
.header_fixed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 500; }
.header__container {
	padding-left: 0;
	padding-right: 0;
	position: relative;
	z-index: 501; }
.header_menu-hidden {
	.header__burger {
		opacity: 0;
		visibility: hidden; } }
.header__burger {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 50px;
	height: 50px;
	vertical-align: middle;
	cursor: pointer;
	pointer-events: initial;
	z-index: 500;
	margin-right: -12px;
	&:hover {
		.header__burger-line {
			&:before {
				transform: translateX(100%); }
			&:after {
				transform: translateX(0%); } } } }
.header__burger-label {
	display: inline-block;
	height: 14px;
	position: absolute;
	top: 50%;
	right: 100%;
	transform: translate(0, -50%);
	text-align: right;
	transition: all 0.3s ease !important;
	// &:after
 }	// 	content: attr(data-arts-burger-label-close)
.header__label-burger_inner {
	position: absolute;
	top: 0;
	right: 0;
	transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, }

.header__burger-line {
	// position: absolute
	position: relative;
	width: 26px;
	height: 2px;
	// left: 0
	// right: 0
	margin-top: 2px;
	margin-bottom: 2px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	// background-color: var(--color-dark-2)
	// background-color: #fff
	@include trans2;
	&:nth-of-type(1) {
		&:before, &:after {}
		transition-delay: 0ms; }
	&:nth-of-type(2) {
		&:before, &:after {
			transition-delay: 50ms; } }
	&:nth-of-type(3) {
		&:before, &:after {
			transition-delay: 100ms; } }
	> &:first-of-type {
		margin-top: auto;
		margin-bottom: 2px; }
	> &:last-of-type {
		margin-top: 2px;
		margin-bottom: auto; }
	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(0%);
		background-color: var(--color-dark-2);
		transition: all 0.3s ease; }
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(calc(-100% - 4px));
		background-color: var(--color-dark-2);
		transition: all 0.5s ease; } }
.header__burger_opened, .header__burger_opened:hover {
	.header__burger-line {
		&:nth-of-type(1) {
			transform: scaleX(1) rotate(45deg) translate(2px, 2px); }
		&:nth-of-type(2) {
			transform: scaleX(1) rotate(-45deg) translate(2px, -2px); }
		&:nth-of-type(3) {
			transform-origin: right center;
			transform: scaleX(0); } } }
.header__wrapper-overlay-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 500;
	overflow: hidden;
	opacity: 0;
	visibility: hidden; }
.header__label {
	white-space: nowrap;
	transition: color 0.3s ease;
	font-size: 14px;
	line-height: 0.9;
	font-weight: 500; }
.header__label_status {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: calc(100% + 2px);
	transition: all 0.3s ease; }
.header__label_opened-back {
	position: absolute;
	top: 50%;
	transform: translate(0%, 100%);
	left: calc(100% + 2px);
	opacity: 0;
	transition: all 0.3s ease; }
.header__label_side {
	position: absolute;
	top: 50%;
	left: 0;
	padding-left: 0;
	padding-right: 0;
	transform: translateY(-50%); }
.header__wrapper-overlay-widgets {
	.widget {
		margin-bottom: 0; } }
.header__wrapper-menu {
	position: relative;
	margin-top: auto;
	margin-bottom: auto;
	padding: calc(var(--gutter-vertical) * 1.5) var(--gutter-horizontal);
	width: 100%;
	height: 100%;
	display: flex !important;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	.scroll-content {
		height: 100%;
		width: 100%;
		display: flex !important;
		flex-direction: column; } }
.header__wrapper-slider {
	padding-left: var(--gutter-horizontal);
	padding-right: var(--gutter-horizontal);
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	pointer-events: auto !important; }
.header__overlay-menu-back {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 0;
	width: 50px;
	height: 50px;
	left: calc(41.666667% + var(--gutter-horizontal) - 20px);
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	pointer-events: initial;
	z-index: 50;
	transition: color 0.3s ease;
	i {
		font-size: 32px !important; }
	&:hover {
		.header__label_status {
			transform: translate(0%, -100%);
			opacity: 0; }
		.header__label_opened-back {
			transform: translate(0%, -50%);
			opacity: 1; } } }
.header__overlay-menu-info {
	opacity: 0;
	visibility: hidden; }
.header_menu-right {
	.menu {
		.sub-menu {
			left: auto;
			right: 1em;
			ul {
				left: auto;
				right: calc(100% + 1px); } } } }
.header_menu-split-center {
	.header__col-right {
		.menu {
			.sub-menu {
				left: auto;
				right: 1em;
				ul {
					left: auto;
					right: calc(100% + 1px); } } } } }

.header__menu-gradient {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 60; }
.header__menu-gradient_top {
	top: 0;
	height: calc(var(--gutter-vertical) * 2); }
.header__menu-gradient_bottom {
	bottom: 0;
	height: calc(var(--gutter-vertical)); }
.header__scroll-container {
	width: 100%;
	height: 100%;
	display: flex !important;
	.scroll-content {
		width: 100%;
		margin-top: auto;
		margin-bottom: auto; } }

@media screen and (max-width: $md) {
	.header__burger {
		width: 32px;
		height: 32px;
		margin-right: -3px;
		margin-left: 3px; }
	.header__burger-label {
		margin-right: 6px; }
	.header__wrapper-menu {
		margin-top: 0;
		padding-top: 5em;
		padding-bottom: 5em; }
	.header__overlay-menu-info {
		display: none; }
	.header__overlay-menu-back {
		left: calc(var(--gutter-horizontal) * -1); }
	.header__wrapper-slider {
		padding-top: 1.5em;
		padding-bottom: 5em;
		border-top 1px solid $color-border-dark {} }
	.header__menu-gradient_top {
		height: calc(var(--gutter-vertical) * 3); }
	.header__menu-gradient_bottom {
		height: calc(var(--gutter-vertical) * 3); } }

/* ======================================================================== */
/* 85. headerLogo */
/* ======================================================================== */
[data-arts-header-logo="primary"], .preloader[data-arts-preloader-logo="primary"]:not(.preloader_ended) + #page-header {
	.logo__img-primary {
		opacity: 1;
		visibility: visible; }
	.logo__img-secondary {
		opacity: 0;
		visibility: hidden; }
	.logo__text-title {
		color: var(--paragraph-color-dark) !important; }
	.logo__text-tagline {
		color: $color-gray-2 !important; } }
[data-arts-header-logo="secondary"], .preloader[data-arts-preloader-logo="secondary"]:not(.preloader_ended) + #page-header {
	.logo__img-primary {
		opacity: 0;
		visibility: hidden; }
	.logo__img-secondary {
		opacity: 1;
		visibility: visible; }
	.logo__text-title {
		color: #fff !important; }
	.logo__text-tagline {
		color: $color-gray-2 !important; } }

/* ======================================================================== */
/* 86. headerSticky */
/* ======================================================================== */
.js-header-sticky {
	transition-property: padding-top, padding-bottom, box-shadow, background-color;
	transition-duration: 0.4s;
	transition-timing-function: ease;
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
	will-change: padding-top, padding-bottom, box-shadow, background-color;
	&:not(.header_sticky) {
		background-color: unset; } }
.header_sticky {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.05);
	&[data-arts-header-sticky-logo="primary"] {
		.logo__img-primary {
			opacity: 1;
			visibility: visible; }
		.logo__img-secondary {
			opacity: 0;
			visibility: hidden; }
		.logo__text-title {
			color: var(--paragraph-color-dark) !important; }
		.logo__text-tagline {
			color: var(--color-gray-1) !important; } }
	&[data-arts-header-sticky-logo="secondary"] {
		.logo__img-primary {
			opacity: 0;
			visibility: hidden; }
		.logo__img-secondary {
			opacity: 1;
			visibility: visible; }
		.logo__text-title {
			color: #fff !important; }
		.logo__text-tagline {
			color: var(--color-gray-2) !important; } }
	&.bg-dark-1, &.bg-dark-2, &.bg-dark-3, &.bg-dark-4, {
		.menu > li > a {
			&:hover {
				color: #fff; } } } }
.header_sticky_no-shadow {
	box-shadow: none !important; }

/* ======================================================================== */
/* 87. headerThemes */
/* ======================================================================== */
.header[data-arts-theme-text='light'], .preloader[data-arts-theme-text='light']:not(.preloader_ended) + #page-header {
	color: $color-gray-2;
	.header__label {
		color: #fff; }
	.logo__text-title {
		color: #fff; }
	a, .button {
		color: var(--color-gray-1);
		&:hover {
			color: #fff; } }
	.header__burger-line {
		&:after, &:before {
			background-color: #fff; } }
	.menu .sub-menu {
		background-color: #333333; }
	.menu .sub-menu > li a:hover {
		background-color: #383838 !important;
		color: #fff;
		border-color: #fff; }
	.menu > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a {
		background-color: #383838 !important;
		color: #fff !important; }
	.menu > li.current-menu-ancestor .sub-menu li.current-menu-item > a {
		background-color: #424242 !important;
		color: #fff !important; } }

/* ======================================================================== */
/* 88. spinner */
/* ======================================================================== */
$offset: 202;
$duration: 1.2s;

.spinner {
	position: fixed;
	left: var(--gutter-horizontal);
	bottom: calc(var(--fix-bar-vh, 30px) + var(--gutter-vertical) * 2);
	width: 30px;
	height: 30px;
	z-index: 10000;
	animation: rotator $duration ease-in-out infinite;
	opacity: 0;
	visibility: hidden; }

@keyframes rotator {
	0% {
		transform: rotate(0deg); }
	100% {
		transform: rotate(270deg); } }

.spinner__path {
	stroke: var(--color-gray-1);
	stroke-dasharray: $offset;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash $duration ease-in-out infinite; }

@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
		transform: rotate(0deg); }
	50% {
		stroke-dashoffset: $offset / 4;
		transform: rotate(135deg); }
	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg); } }

/* ======================================================================== */
/* 89. breadcrumbs */
/* ======================================================================== */
.breadcrumbs {
	@include reset-ul;
	@extend  .h6;
	li {
		display: inline-block; }
	li:not(:last-child):after {
		content: '/';
		color: $color-dark-1;
		display: inline-block;
		margin-left: 6px;
		margin-right: 5px;
		@include trans1; } }

/* ======================================================================== */
/* 90. menu */
/* ======================================================================== */
#page-header .menu {
	position: relative;
	@include reset-ul;
	margin: 0 -1em;
	> li {
		display: inline-block;
		&:not(:last-child) {
			margin-right: 1em; }
		a {
			display: block;
			padding: 0.5em 1em; } }
	.menu-item-has-children {
		position: relative;
		> a {
			&:hover {
				~ ul {
					opacity: 1;
					visibility: visible;
					transform: translate(0px, 0px);
					z-index: 50; } } } }
	> li > a {
			background-image: linear-gradient(currentColor, currentColor);
			background-position: calc(100% - 1em) calc(100% - 0.25em);
			background-repeat: no-repeat;
			background-size: 0% 2px;
			transition: background-size .4s ease, color 0.2s ease-in-out;
			&:hover {
				background-position: 1em calc(100% - 0.25em);
				background-size: calc(100% - 2em) 2px; } }
	> li.current-menu-item {
		> a {
			background-size: calc(100% - 2em) 2px;
			background-position: 1em calc(100% - 0.25em); } }
	> li.current-menu-ancestor {
		> a {
			background-position: 1em calc(100% - 0.25em);
			background-size: calc(100% - 2em) 2px; }
		.sub-menu li.current-menu-ancestor > a {
			background-color: #fafafa; }
		.sub-menu li.current-menu-item > a {
			background-color: #fafafa;
			color: var(--color-dark-2); } } }

.menu_hover {
	> li > a:not(:hover) {
		background-position: calc(100% - 1em) calc(100% - 0.25em);
		// background-size: 0% 2px
		background-size: 0% 2px;
 }		// background-size: calc(100% - 2em) 2px
	> li.current-menu-item {
		> a {
			background-position: calc(100% - 1em) calc(100% - 0.25em);
			background-size: 0% 2px; } }
	> li.current-menu-ancestor {
		> a {
			background-position: calc(100% - 1em) calc(100% - 0.25em);
			background-size: 0% 2px; } } }
.menu.menu_disabled {
	pointer-events: none !important;
	* {
		pointer-events: none !important; }
	.sub-menu {
		opacity: 0 !important;
		visibility: hidden !important;
		transform: translate(0px, 1em) !important; } }
.menu {
	.sub-menu {
		position: absolute;
		top: 100%;
		left: 1em;
		transform: translate(0px, 1em);
		@include reset-ul;
		opacity: 0;
		visibility: hidden;
		@include trans1;
		z-index: -1;
		background-color: #fff;
		border-radius: 2px;
		text-align: left;
		box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04);
		&:hover {
			opacity: 1;
			visibility: visible;
			transform: translate(0, 0);
			z-index: 50; }
		> li {
			white-space: nowrap;
			a {
				padding: 0.5em 1em;
				background-color: transparent;
				border-left: 2px solid transparent;
				&:hover {
					background-color: #fafafa;
					border-color: var(--color-dark-2);
					color: var(--color-dark-2); } } }
		ul {
			top: 0;
			left: calc(100% + 1px);
			transform: translate(0.5em, 0);
			opacity: 0;
			visibility: hidden; } } }

@media screen and (max-width: $xl) {
	.menu {
		> li {
			&:not(:last-child) {
				margin-right: 0.25em; } } } }

/* ======================================================================== */
/* 91. menuOverlay */
/* ======================================================================== */
.menu-overlay {
	@include reset-ul;
	// position: relative
	display: flex;
	flex-wrap: wrap;
	margin: auto 0;
	z-index: 50;
	> li {
		display: flex;
		align-items: center;
		flex: 1 0 50%;
		@extend .my-xsmall;
		> a {
			display: inline-block; } }
	li a .menu-overlay__heading .arts-split-text__line {
			display: inline-block !important;
			background-image: linear-gradient(currentColor, currentColor);
			background-position: 100% 100%;
			background-repeat: no-repeat;
			background-size: 0% 1px;
			transition: background-size .3s ease, color 0.2s ease-in-out; }
	> li.current-menu-item {
		> a .menu-overlay__heading .arts-split-text__line {
			background-size: 100% 1px;
			background-position: 0% 100%; } }
	> li.current-menu-ancestor {
		> a .menu-overlay__heading .arts-split-text__line {
			background-size: 100% 1px;
			background-position: 0% 100%; }
		.sub-menu li.current-menu-ancestor > a .menu-overlay__heading .arts-split-text__line {
			background-size: 100% 1px;
			background-position: 0% 100%; }
		.sub-menu li.current-menu-item > a .menu-overlay__heading .arts-split-text__line {
			background-size: 100% 1px;
			background-position: 0% 100%; } }
	li a {
		&:hover {
			.menu-overlay__heading .arts-split-text__line {
				background-position: 0% 100%;
				background-size: 100% 1px; } } } }
.menu-overlay_hover {
	li a:not(:hover) .menu-overlay__heading .arts-split-text__line {
		background-position: 100% 100%; }
	> li.current-menu-item {
		> a:not(:hover) .menu-overlay__heading .arts-split-text__line {
			background-size: 0% 1px; } }
	> li.current-menu-ancestor {
		> a:not(:hover) .menu-overlay__heading .arts-split-text__line {
			background-size: 0% 1px; }
		.sub-menu li.current-menu-ancestor > a:not(:hover) .menu-overlay__heading .arts-split-text__line {
			background-size: 0% 1px; }
		.sub-menu li.current-menu-item > a:not(:hover) .menu-overlay__heading .arts-split-text__line {
			background-size: 0% 1px; } } }
.menu-overlay__item-wrapper {
	width: 100%;
	height: 100%; }
.menu-overlay .sub-menu {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: calc(var(--gutter-vertical) * 2) var(--gutter-horizontal);
	// padding-top: calc(var(--gutter-vertical) * 2)
	// padding-right: var(--gutter-horizontal)
	// padding-bottom: calc(var(--gutter-vertical) * 2)
	// padding-left: 0
	list-style-type: none;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	max-height: 100vh;
	> li {
		display: block;
		> a {
			display: inline-block;
			padding: 8px 0; } } }
.menu-overlay__subheading {
	display: block; }
[data-arts-header-animation='intransition'] {
	.menu-overlay li a .menu-overlay__heading .arts-split-text__line {
		background-position: 100% 100% !important;
		background-size: 0% 1px !important;
		transition: background-size .2s ease, color 0.2s ease-in-out !important; } }

@media screen and (max-width: $sm) {
	.menu-overlay {
		display: block;
		margin-top: 0; }
	.menu-overlay > li {
		flex: 1 1 100%;
		width: 100%; }
	.menu-overlay > li {
		margin-top: 2em;
		margin-bottom: 2em; }
	.menu-overlay > li:first-child {
		margin-top: 0; }
	.menu-overlay > li:last-child {
		margin-bottom: 0; }
	.menu-overlay .sub-menu {
		justify-content: flex-start;
		padding-top: 5em; } }

/* ======================================================================== */
/* 92. asideCounters */
/* ======================================================================== */
.aside-counters {}

/* ======================================================================== */
/* 93. section404 */
/* ======================================================================== */
.section-404 {}

/* ======================================================================== */
/* 94. sectionAwards */
/* ======================================================================== */
.section-awards {}
.section-awards__wrapper-item {}

/* ======================================================================== */
/* 95. sectionBlog */
/* ======================================================================== */
.section-blog__wrapper-post {
	&:last-child {
		margin-bottom: 0 !important; } }

@media screen and (max-width: $xs) {
	.section-blog {
		.section-offset__content {
			transform: none !important; } } }

/* ======================================================================== */
/* 96. sectionComingSoon */
/* ======================================================================== */
.section-coming-soon {}

/* ======================================================================== */
/* 97. sectionForm */
/* ======================================================================== */
@media screen and (max-width: $xs) {
  .section-form {
    padding-bottom: 0 !important; } }

/* ======================================================================== */
/* 98. sectionContent */
/* ======================================================================== */
.section-content {}
.section-content__divider {
	position: absolute;
	top: 0;
	left: calc(var(--bs-gutter-x) / 2);
	right: calc(var(--bs-gutter-x) / 2);
	width: auto;
	// left: 20px
	// right: 20px
	height: 1px;
	background-color: $color-border-dark; }
.section-content__wrapper-item {
	position: relative;
	&:first-child {
		padding-top: 0 !important;
		.section-content__divider {
			display: none; } }
	&:last-child {
		padding-bottom: 0 !important;
		border-bottom: none; } }
.section-content__content {
	position: relative; }

/* ======================================================================== */
/* 99. sectionFullscreenColumns */
/* ======================================================================== */
.section-fullscreen-columns__border:not(:last-child) {
  border-right: 1px solid $color-border-dark; }

@media screen and (max-width: $md) {
  .section-fullscreen-columns__border:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid $color-border-dark; } }

@media screen and (min-width: $md + 1) {
  .section-fullscreen-columns__header {
    @include fluid('padding-top', $distance-min-small, $distance-max-small);
    @include fluid('padding-bottom', $distance-min-small, $distance-max-small); } }

/* ======================================================================== */
/* 100. sectionImage */
/* ======================================================================== */
.section-image {
	// overflow: hidden
	display: flex;
	flex-direction: column; }
.section-image__wrapper {
	position: relative;
	width: 100%;
	height: 100%; }
.section-image__overlay {
	z-index: 0; }

/* ======================================================================== */
/* 101. sectionIntro */
/* ======================================================================== */
.section-intro {}

/* ======================================================================== */
/* 102. sectionList */
/* ======================================================================== */
.section-list {}
.section-list__item {
	display: flex;
	align-items: center;
	margin-bottom: 2em;
	&:last-child {
		margin-bottom: 0; } }
.section-list__counter {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	@include fluid('width', 50, 72);
	@include fluid('height', 50, 72);
	// border-radius: 50%
	// border: 1px solid $color-border-dark
	flex-shrink: 0;
	margin-right: 1em;
	.svg-circle {
		stroke-width: 1px;
		stroke: $color-border-dark;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0; } }
.section-list__wrapper-circle {
	position: absolute;
	top: 0;
	le: 0;
	width: 100%;
	height: 100%; }
.section-list__content {
	width: 100%;
	ul, li {
		padding-left: 0 !important; }
	li:last-child {
		margin-bottom: 0; } }

/* ======================================================================== */
/* 103. sectionLogos */
/* ======================================================================== */
.section-logos {}
.section-logos__wrapper-logo {
	// width: 100%
	// height: 250px
	display: flex;
	align-items: center;
	justify-content: center;
	@include trans1;
	transition-property: opacity;
	opacity: .5;
	&:hover {
		opacity: 1; } }

/* ======================================================================== */
/* 104. sectionListBackgrounds */
/* ======================================================================== */
.section-list-backgrounds {}

/* ======================================================================== */
/* 105. sectionMap */
/* ======================================================================== */
.section-map {}

/* ======================================================================== */
/* 106. sectionNavProjects */
/* ======================================================================== */
.section-nav-projects {
	flex-wrap: wrap;
 }	// overflow: hidden
.section-nav-projects__next-image {
	width: 100%;
	height: 30vh;
	.lazy-wrapper {
		max-width: 100% !important;
		height: 100%; }
	.lazy {
		display: inline-block;
		width: auto;
		// width: 100%
		height: 100% !important;
		padding-bottom: 0 !important;
		img {
			position: relative !important;
			width: auto;
			height: 100%; } }
	.js-transition-img__transformed-el {
		width: 100%;
		height: 100%; } }
.section-nav-projects__header {
	cursor: pointer; }
.section-nav-projects__next-image {
	cursor: pointer; }
.section-nav-projects__link {
  display: block; }
.section-nav-projects__subheading {
	position: relative; }
.section-nav-projects__label_scroll {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	transform: translateY(100%);
	opacity: 0;
	visibility: hidden; }
.section-nav-projects__label {
	display: inline-block; }
.section-nav-projects__inner {
	// height: 100vh
	height: calc(var(--fix-bar-vh, 1vh) * 100); }
.section-nav-projects__spacer {
	width: 100%;
	// height: 33vh
	height: calc(var(--fix-bar-vh, 1vh) * 33); }

@media screen and (max-width: $sm) {
	.section-nav-projects__inner {
		height: auto; } }

/* ======================================================================== */
/* 107. sectionMasthead */
/* ======================================================================== */
.section-masthead {}
.section-masthead__inner {
	position: relative;
	overflow: hidden;
	z-index: 50; }
.section-masthead__background {
	overflow: hidden; }
.section-masthead__background_fullscreen, .section-masthead__background_halfscreen {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden; }
.section-masthead__info {
	position: relative;
	z-index: 50; }
.section-masthead__wrapper-content {
	position: relative;
	z-index: 50; }
.section-masthead__wrapper-scroll-down {
	display: inline-block;
	position: absolute;
	bottom: var(--gutter-horizontal);
	z-index: 50; }
.text-center .section-masthead__wrapper-scroll-down {
	left: 0;
	right: 0;
	margin: auto; }
.text-start .container-fluid .section-masthead__wrapper-scroll-down {
	left: var(--gutter-horizontal); }
.text-end .container-fluid .section-masthead__wrapper-scroll-down {
	right: var(--gutter-horizontal); }
.text-start .container .section-masthead__wrapper-scroll-down {
	left: 0; }
.text-end .container .section-masthead__wrapper-scroll-down {
	right: 0; }

/* ======================================================================== */
/* 108. sectionScrollThemeSwitch */
/* ======================================================================== */
.section-scroll-theme-switch {
  @include trans1; }

/* ======================================================================== */
/* 109. sectionServices */
/* ======================================================================== */
.section-services {}

/* ======================================================================== */
/* 110. sectionSliderImages */
/* ======================================================================== */
@media screen and (max-width: $xs) {
  .section-slider-images {
    padding-left: 0;
    padding-right: 0; } }

/* ======================================================================== */
/* 111. sectionSliderProjects */
/* ======================================================================== */
.section-slider-projects {}

/* ======================================================================== */
/* 112. sectionSliderProjectsFullscreen */
/* ======================================================================== */
.section-slider-projects-fullscreen {}

/* ======================================================================== */
/* 113. sectionTestimonials */
/* ======================================================================== */
.section-testimonials {}

/* ======================================================================== */
/* 114. sectionVideo */
/* ======================================================================== */
.section-video {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center; }
.section-video__link {
	display: block;
	margin: auto;
	@include fluid('width', 80, 160);
	@include fluid('height', 80, 160);
	border-radius: 100%;
	will-change: transform;
	font-weight: 500;
	color: #fff;
	z-index: 60; }
.section-video__link-inner {
	background-color: $color-dark-1;
	color: #fff;
	border-radius: 100%;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center; }
.no-touchevents {
	.section-video__link-inner[data-arts-cursor-label]:not([data-arts-cursor-label="false"]) {
		&:hover {
			.section-video__icon {
				opacity: 0;
				visibility: hidden;
				transform: translateY(20px); } } } }
.section-video__link-inner[data-arts-cursor-label]:not([data-arts-cursor]):not([data-arts-cursor-label="false"]) {
	&:after {
		content: attr(data-arts-cursor-label);
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 50;
		transform: translate(-50%, -20px);
		opacity: 0;
		visibility: hidden;
		@include trans1; }
	&:hover {
		&:after {
			opacity: 1;
			visibility: visible;
			transform: translate(-50%, -50%); } } }

.section-video__container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
 }	// height: 100%
.section-video__icon.material-icons {
	@include trans1;
	@include fluid-type(24, 32);
	> * {
		color: #fff !important; } }

/* ======================================================================== */
/* 115. sliderImages */
/* ======================================================================== */
.slider-images {
	position: relative; }
.slider-images__slider {
	&[data-drag-class] {
		.slider-images__slide {
			@include trans3; }
		img {
			@include trans3; } } }
.slider-images__captions {
	background-image: linear-gradient($color-border-dark, $color-border-dark);
	background-position: 0% 0%;
	background-repeat: no-repeat;
	background-size: 100% 2px;
	.swiper-slide {
		cursor: pointer;
		opacity: .4;
		@include trans1;
		&:hover {
			opacity: 1; } }
	.swiper-slide-active {
		opacity: 1; } }
.slider-images__underline {
	pointer-events: none;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: $color-dark-1;
	z-index: 50; }
.slider-images_scale-up {
	.slider-images__slide {
		transform: scale(0.95); }
	img {
		transform: scale(1.05); } }

/* ======================================================================== */
/* 116. sliderMenu */
/* ======================================================================== */
.slider-menu {
  width: 100%;
  height: 100%;
 }  // max-width: 800px
.slider-menu__header {
  padding-top: 2em;
  padding-left: 2em; }
.slider-menu__slide {
  height: auto;
  // opacity: 0.2
  transition: opacity 0.3s ease;
  * {
    pointer-events: none !important; }
  &.swiper-slide-active {
    opacity: 1;
    pointer-events: auto;
    * {
      pointer-events: auto !important; } }
  &:not(.swiper-slide-active) {
    .slider-menu__header {
      a {
 } } } }        // opacity: 0
.slider-menu__wrapper-image {
  display: block;
  img {
    transform: scale(1.1);
    transform-origin: center center; } }


@media screen and (max-width: $md) {
  .slider-menu__header {
    padding-top: 1em;
    padding-left: 1em; }
  .slider-menu {
    margin-left: calc(var(--gutter-horizontal) * -1);
    margin-right: calc(var(--gutter-horizontal) * -1);
    width: auto; } }

/* ======================================================================== */
/* 117. sliderProjects */
/* ======================================================================== */
.slider-projects {}
.slider-projects__header {
	padding-top: 1.5em;
	padding-left: 1.5em; }
.slider-projects__slide {
	// opacity: 0.2
	transition: opacity 0.3s ease;
	* {
		pointer-events: none !important; }
	&.swiper-slide-active {
		opacity: 1;
		pointer-events: auto;
		* {
			pointer-events: auto !important; } }
	&:not(.swiper-slide-active) {
		.slider-projects__header {
			a {
 } } } }				// opacity: 0
.slider-projects__wrapper-image {
	display: block;
	img {
		transform: scale(1.1);
		transform-origin: center center;
		user-select: none; } }
.slider-projects__wrapper_bottom-left {
	position: absolute;
	left: var(--gutter-horizontal);
	right: auto;
	bottom: calc(var(--gutter-vertical) / 2); }
.slider-projects__wrapper_bottom-right {
	position: absolute;
	right: var(--gutter-horizontal);
	left: auto;
	bottom: calc(var(--gutter-vertical) / 2); }
.slider-projects__wrapper_bottom-center {
	position: absolute;
	display: inline-block;
	left: 50%;
	transform: translateX(-50%);
	bottom: calc(var(--gutter-vertical) / 2); }

@media screen and (max-width: $md) {
	.slider-projects {
		position: static !important;
 }		// height: auto !important
	.slider-projects__slide {
		// display: block !important
		// padding-top: 0 !important
 }		// padding: 0 !important
	// .slider-projects__arrows
	//   left: var(--gutter-horizontal)
	//   right: var(--gutter-horizontal)
	//   margin-left: auto !important
	//   margin-right: auto !important
	.slider-projects__header {
		padding-top: 1em;
		padding-left: 0; }
	.slider-projects__wrapper_bottom-center {
		bottom: var(--gutter-vertical);
		left: auto;
		right: var(--gutter-horizontal);
		transform: none; }
	// .slider-projects__wrapper_bottom-center
	//   position: relative
	//   left: auto
	//   transform: none
	//   bottom: auto
	// .slider-projects__wrapper_bottom-left
	//   display: block
	//   position: relative
	//   left: auto
	//   bottom: auto
	//   width: 100%
	//   z-index: 60
	//   margin: 0 !important
	//   text-align: center
 }	//   width: 100%

/* ======================================================================== */
/* 118. sliderProjectsFullscreen */
/* ======================================================================== */
.slider-projects-fullscreen {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center; }
.slider-projects-fullscreen__images {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	&[data-drag-class] {
		.slider-projects-fullscreen__slide {
			@include trans3; }
		img {
			@include trans3; } } }
.slider-projects-fullscreen__content {
	// overflow: initial
	max-width: 100vw;
	max-height: 100vh;
	z-index: 60;
	.swiper-slide {
		// opacity: 1 !important
		// z-index: 50
		// *
 }		// 	z-index: 50
	.swiper-slide:not(.swiper-slide-active) {
		// pointer-events: none !important
		// z-index: 1
		// *
		// 	pointer-events: none !important
 } }		// 	z-index: 1
.section-fullheight__inner_mobile {
	.slider-projects-fullscreen__content {
		max-height: calc(var(--fix-bar-vh, 1vh) * 100); } }
.slider-projects-fullscreen__container-bottom {
	padding-top: var(--gutter-vertical);
	padding-bottom: var(--gutter-vertical); }
.slider-projects-fullscreen__content_bottom {}
.slider-projects-fullscreen__wrapper-thumbs {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	margin-left: auto;
	margin-right: auto;
	height: 136px;
	width: 100%;
	max-width: 1100px;
	z-index: 60;
	.swiper-slide {
		cursor: pointer; } }
.slider-projects-fullscreen__wrapper-button {
	height: 136px;
	width: 136px;
	flex: 1 0 auto; }
.slider-projects-fullscreen__thumbs {
	flex: 1 1 100%; }
.slider-projects-fullscreen__button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 0;
	padding: 0; }
.slider-projects-fullscreen__col-content {
	flex: 1 1 36vw;
	width: 36vw;
	max-width: 720px; }
.slider-projects-fullscreen__col-images {
	flex: 2 1 64vw;
	height: 100%;
	width: 64vw;
	max-width: 64vw; }
.slider-projects-fullscreen__slide {
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: auto;
		height: calc(100vh - var(--gutter-horizontal) * 4); } }

.slider-projects-fullscreen__fluid-container {
	padding-top: calc(var(--gutter-vertical) * 2);
	padding-bottom: calc(var(--gutter-vertical) * 2);
	z-index: 50; }
.slider-projects-fullscreen__sidebar {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: calc(var(--gutter-horizontal) * 2);
	z-index: 10; }
.slider-projects-fullscreen__counter {
	position: absolute;
	right: var(--gutter-horizontal);
	bottom: calc(var(--gutter-vertical) / 1.5);
	width: auto;
	padding-left: 0;
	padding-right: 0;
	z-index: 60; }
.slider-projects-fullscreen__arrows {
	position: absolute;
	left: var(--gutter-horizontal);
	bottom: calc(var(--gutter-vertical) / 1.5);
	z-index: 60;
	margin-left: -16px;
	margin-bottom: -16px; }
.slider-projects-fullscreen__arrows_left {
	bottom: 0;
	top: 0;
	margin-bottom: auto;
	margin-top: auto; }
.slider-projects-fullscreen__arrows_right-bottom {
	bottom: calc(var(--gutter-vertical) / 1.5);
	right: var(--gutter-horizontal);
	left: auto;
	top: auto;
	margin-left: 0;
	margin-right: -8px; }
.slider-projects-fullscreen__arrows_right-center {
	bottom: 0;
	right: var(--gutter-horizontal);
	left: auto;
	top: 50%;
	transform: translateY(-50%); }
.slider-projects-fullscreen__scrollbar {
	position: absolute;
	right: var(--gutter-horizontal);
	padding-left: 0 !important;
	padding-right: 0 !important;
	top: 0;
	bottom: 0; }
.slider-projects-fullscreen__wrapper-scrollbar {
	z-index: 50; }
.slider-projects-fullscreen__images_scale-up {
	.slider-projects-fullscreen__slide {
		transform: scale(0.95); }
	img {
		transform: scale(1.05); } }
.slider-projects-fullscreen.hover-zoom {
	.swiper-slide-active {
		transform: scale(1);
		img {
			transform: scale(1.05); } } }
.slider-projects-fullscreen__wrapper-image {
	display: block;
	img {
		transform: scale(1.1);
		transform-origin: center center;
		user-select: none; } }

@media screen and (max-width: $xxl) {
	.slider-projects-fullscreen__fluid-container {
		padding-top: calc(var(--gutter-vertical) * 3);
		padding-bottom: calc(var(--gutter-vertical) * 3); } }
@media screen and (max-width: $xl) {
	.slider-projects-fullscreen__slide img {
		width: 100%;
		height: auto;
		opacity: .2; } }

@media screen and (max-width: $md) {
	.slider-projects-fullscreen__wrapper-scrollbar_mobile-absolute {
		position: absolute;
		display: inline-block;
		right: 0;
		left: auto; }
	.slider-projects-fullscreen__col-images {
		flex: 1 1 100%;
		width: 100%;
		max-width: unset;
		margin-left: calc(var(--gutter-horizontal) * -1);
		margin-right: calc(var(--gutter-horizontal) * -1);
		overflow: hidden; }
	.slider-projects-fullscreen__sidebar {
		width: 0; }
	.slider-projects-fullscreen__col-content {
		padding-right: 0;
		padding-left: 0;
		padding-top: 2em;
		padding-bottom: 2em; }
	// .slider-projects-fullscreen__images
	//   bottom: auto
	.slider-projects-fullscreen__arrows:not(.slider-projects-fullscreen__arrows_left) {
		margin-bottom: 4px; }
	.slider-projects-fullscreen__arrows {
		left: 0;
		margin-left: 0; }
	.slider__wrapper-arrows_vertical {
		&.slider-projects-fullscreen__arrows_left {
			width: 50px; }
		&.slider-projects-fullscreen__arrows_right-center {
			width: 50px; } }

	.slider-projects-fullscreen__arrows_left {
		left: 0; }
	.slider-projects-fullscreen__arrows_right-center {
		left: auto;
		right: 0; }
	.slider-projects-fullscreen__arrows_right-bottom {
		margin-left: 0;
		left: auto;
		right: 0; }
	.slider-projects-fullscreen__counter {
		bottom: calc(var(--gutter-vertical) * 2); } }

/* ======================================================================== */
/* 119. sliderProjectsHalfscreen */
/* ======================================================================== */
.slider-projects-halfscreen {}
.slider-projects-halfscreen__col-images {
  position: relative;
  max-width: calc(50vw - var(--gutter-horizontal) * 2);
  padding-left: 0;
  padding-right: 0; }

@media screen and (max-width: $md) {
  .slider-projects-halfscreen__col-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    .swiper-slide {
      padding: 0; } } }

/* ======================================================================== */
/* 120. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials {}
.slider-testimonials__wrapper-circle {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	@include fluid('width', 80, 145);
	@include fluid('height', 80, 145);
	border-radius: 50%;
	border: 2px solid $color-border-dark;
	.svg-circle {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0; } }
.slider-testimonials__quote {
	@include fluid('width', 30, 50); }
.slider-testimonials__text {
	max-width: 100%; }

/* ======================================================================== */
/* 121. widgetNavMenu */
/* ======================================================================== */
.widget_nav_menu {
	ul.menu {
		> li {
			white-space: normal;
			word-break: break-word;
			a {
				display: inline-block;
				padding: 0;
				font-size: 16px;
				line-height: 1.5;
				font-weight: normal;
				text-transform: none;
				letter-spacing: 0;
				// color: var(--color-gray-1)
				// &:after, &:before
				// 	display: none
				// &:hover
 } }				// 	color: var(--color-dark-1)
		> li.menu-item-has-children {
			margin-bottom: 1.5em !important;
			a {
				&:after {
					display: none; } } } } }

/* ======================================================================== */
/* 122. widgetSocial */
/* ======================================================================== */
.widget-social {}

/* ======================================================================== */
/* 123. widgetText */
/* ======================================================================== */
.widget_text {
	.textwidget {
		> *:first-child {
			margin-top: 0; }
		> *:last-child {
			margin-bottom: 0; } } }
