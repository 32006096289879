/* ======================================================================== */
/* 51. themes */
/* ======================================================================== */
[data-arts-theme-text="light"]
	color: $color-gray-2
	.xxl
		color: var(--xxl-color-light)
	.xl
		color: var(--xl-color-light)
	h1, .h1
		color: var(--h1-color-light)
	h2, .h2
		color: var(--h2-color-light)
	h3, .h3
		color: var(--h3-color-light)
	h4, .h4
		color: var(--h4-color-light)
	h5, .h5
		color: var(--h5-color-light)
	h6, .h6
		color: var(--h6-color-light)
	p, .paragraph
		color: var(--paragraph-color-light)
	.subheading
		color: var(--subheading-color-light)
	blockquote, .blockquote
		color: var(--blockquote-color-light)
		p
			color: var(--blockquote-color-light)
		cite
			color: var(--blockquote-color-light)
	a, .button
		color: var(--color-gray-1)
		&:hover
			color: #fff
	.button.button_bordered
		&:hover
			border-color: #fff
	.header__label
		color: #fff
	.header__overlay-menu-back
		&:hover
			color: var(--color-gray-1)
	.breadcrumbs, .categories
		li:not(:last-child):after
			color: #fff
	.slider__scrollbar
		background-color: var(--color-gray-3)
	.slider__scrollbar-handle
		background-color: #fff
		color: #fff
	.slider__counter_current
		color: #fff
	.slider__arrow
		&:hover
			color: #fff
	.slider__dot svg .circle
		stroke: #fff
	.slider__dot
		border-color: $color-border-light
		&:after
			background-color: $color-border-light
		&:hover
			&:after
				background-color: #fff
	.slider__dot_active
		&:after
			background-color: #fff
	.section__headline
		background-color: #dadada
	.post-meta
		li:not(:last-child):after
			color: #dadada
