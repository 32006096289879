/* ======================================================================== */
/* 47. smoothScroll */
/* ======================================================================== */
.smooth-scroll
	overflow: hidden
	width: 100vw
	height: 100vh
[data-arts-os-animation]:not([data-arts-os-animation="animated"]) > *, [data-arts-os-animation][data-arts-os-animation-name]:not([data-arts-os-animation="animated"])
	opacity: 0
	visibility: hidden
	pointer-events: none !important
	*
		pointer-events: none !important
// .section-masthead[data-arts-os-animation]:not([data-arts-os-animation="animated"]) > *
// 	opacity: 0
// 	visibility: hidden

body.elementor-editor-active [data-arts-os-animation]
	opacity: 1
	visibility: visible
[data-arts-scroll-fixed]
	width: 100% !important
	left: 0 !important
	top: 0 !important
