/* ======================================================================== */
/* 103. sectionLogos */
/* ======================================================================== */
.section-logos
.section-logos__wrapper-logo
	// width: 100%
	// height: 250px
	display: flex
	align-items: center
	justify-content: center
	+trans1
	transition-property: opacity
	opacity: .5
	&:hover
		opacity: 1
